<template>
  <div class="input-wrap" style="height: 100%;">
    <label class="input-main" v-if="label" v-bind="$attrs">{{ label }}</label>
    <input class="input-main" :class="{ 'input-error': error }" v-if="type === 'text'" type="text" :value="modelValue" :placeholder="error ? errorMessage : placeholder"
       @input="$emit('update:modelValue', $event.target.value)" v-bind="$attrs">
       <textarea :class="{'input-main': true, 'input-error': error}" v-else-if="type === 'multiline'" :value="modelValue" :placeholder="error ? errorMessage : placeholder"
          @input="$emit('update:modelValue', $event.target.value)" v-bind="$attrs"></textarea>
    <div v-if="type === 'select'" class="dropdown" ref="dropdownRef">
      <input class="input-main" :class="{ 'input-error': error }" type="text" style="width: 100%;" :value="selectedOptionText" :placeholder="error ? errorMessage : placeholder"
        readonly v-bind="$attrs" @click="toggleDropdown">
      <img :src="currentIcon" class="dropdown-icon" :class="{ 'rotate-icon': showDropdown }" @click="iconClickHandler">
      <div v-if="showDropdown" class="dropdown-panel" :style="{ '--dropdown-max-height': dropdownMaxHeight }">
        <input class="input-search" type="text" placeholder="Search ..." @input="searchHandler($event.target.value)" />
        <div class="options-container">
          <div v-for="(option, index) in filteredOptions" :key="index" @click.stop="selectOption(option)">
            {{ option.text }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="type === 'multipleSelect'" class="dropdown" ref="dropdownRef">
      <input class="input-main" type="text" style="width: 100%;" :value="selectedItemsCount" :placeholder="placeholder"
        readonly v-bind="$attrs" @click="toggleDropdown">
      <img :src="currentIcon" class="dropdown-icon" :class="{ 'rotate-icon': showDropdown }" @click="iconClickHandler">
      <div v-if="showDropdown" class="dropdown-panel" :style="{ '--dropdown-max-height': dropdownMaxHeight }">
        <input class="input-search" type="text" placeholder="Search ..." @input="searchHandler($event.target.value)" />
        <div class="options-container">
          <div v-for="(option, index) in filteredOptions" :key="index" class="checkbox-option">
            <input type="checkbox" :id="'checkbox-' + index" :value="option.value"
                  :checked="isSelected(option.value)" @change="toggleSelection(option.value, index)" 
                  :disabled="shouldDisableCheckbox(option.value)"
                  v-model="selectedValues"
                  >
            <label :for="'checkbox-' + index">{{ option.text }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, onUnmounted, computed, nextTick } from 'vue';
import { watch, watchEffect } from 'vue';

const props = defineProps({
  label: {
    type: [String, Boolean],
    default: false,
  },
  modelValue: {
    type: [String, Array, Number],
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: 'text',
  },
  options: {
    type: Array,
    default: () => [],
  },
  error: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: '',
  },
  maxHeight: {
    type: String,
    default: ""
  },
});

const currentIcon = ref('./images/arrow-circle-left.svg');

const dropdownMaxHeight = computed(() => {
  return props.maxHeight ? `${props.maxHeight}px` : "none";
});

onMounted(() => {
  window.addEventListener('click', closeDropdown);
  
  if ((props.type === 'select' && props.modelValue) || 
      (props.type === 'multipleSelect' && props.modelValue && props.modelValue.length > 0)) {
    currentIcon.value = './images/close.svg';
  } else {
    currentIcon.value = './images/arrow-circle-left.svg';
  }
});

onUnmounted(() => {
  window.removeEventListener('click', closeDropdown);
});

watch(() => props.modelValue, (newValue) => {
  if (!newValue) {
    currentIcon.value = './images/arrow-circle-left.svg';
  }
});

watchEffect(() => {
  if ((props.type === 'select' && props.modelValue) || 
      (props.type === 'multipleSelect' && props.modelValue && props.modelValue.length > 0)) {
    currentIcon.value = './images/close.svg';
  } else {
    currentIcon.value = './images/arrow-circle-left.svg';
  }
});

const emit = defineEmits(['update:modelValue']);

const showDropdown = ref(false);
// const selectedOptionText = ref(props.modelValue);
const dropdownRef = ref(null);

const adjustDropdownPosition = () => {
  nextTick(() => {
    if (!dropdownRef.value) return;

    const dropdownPanel = dropdownRef.value.querySelector('.dropdown-panel');
    if (!dropdownPanel) return;

    const rect = dropdownPanel.getBoundingClientRect();

    if (rect.right < 200) {
      const inputSearch = dropdownPanel.querySelector('.input-search');
      inputSearch.style.left = `0px`;
      inputSearch.style.right = 'auto';

      const optionsContainer = dropdownPanel.querySelector('.options-container');
      optionsContainer.style.left = `0px`;
      optionsContainer.style.right = 'auto';
    } 
    
  });
};


const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
  if (showDropdown.value) {
    // Pastikan nextTick digunakan untuk menunggu update DOM selesai
    nextTick(() => {
      adjustDropdownPosition();
    });
  }
};

const iconClickHandler = () => {
  if (selectedOptionText.value && currentIcon.value.includes('close.svg')) {
    clearSelection();
  } else {
    toggleDropdown();
  }
};

const closeDropdown = (event) => {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
    showDropdown.value = false;
    searchText.value = '';
  }
};

const clearSelection = () => {
  selectedOptionText.value = '';
  emit('update:modelValue', '');
  currentIcon.value = './images/arrow-circle-left.svg';
  showDropdown.value = false;
};

const selectOption = (option) => {
  currentIcon.value = './images/close.svg'; 
  showDropdown.value = false;
  selectedOptionText.value = option.text;
  emit('update:modelValue', option.value);
  searchText.value = '';
};

const searchText = ref('');

const filteredOptions = computed(() => {
  if (!searchText.value) {
    return props.options;
  }
  return props.options.filter(option => 
    option.text.toLowerCase().includes(searchText.value.toLowerCase())
  );
});

const searchHandler = (value) => {
  searchText.value = value;
};

const selectedOptionText = computed(() => {
  if (props.type === 'select') {
    const option = props.options.find(o => o.value === props.modelValue);
    return option ? option.text : '';
  }
  return ''; // Mengembalikan string kosong jika tidak ada yang cocok atau bukan tipe 'select'
});

const isSelected = computed(() => {
  return (optionValue) => props.modelValue.includes(optionValue);
});

const toggleSelection = (optionValue) => {
  const newValue = [...props.modelValue];
  const index = newValue.indexOf(optionValue);
  if (index === -1) {
    if (newValue.length < 2) {
      newValue.push(optionValue);
    } else {
      alert('You can only select up to 2 items.');
      return;
    }
  } else {
    newValue.splice(index, 1);
  }
  emit('update:modelValue', newValue);
};

const selectedValues = ref([]);

const shouldDisableCheckbox = (checkboxId) => {
  // Disable checkbox if more than 2 options have been selected and this checkbox is not included in the selected options
  return selectedValues.value.length >= 2 && !selectedValues.value.includes(checkboxId);
};

const selectedItemsCount = computed(() => {
  const count = props.modelValue.length;
  return count === 0 ? '' : `${count} Selected`;
});
</script>

<style>
.input-wrap {
  display: flex;
  flex-direction: column;

  input {
    padding: 8px 12px;
    font-size: 16px;
  }
}

.dropdown {
  position: relative;
}

.dropdown input {
  cursor: pointer;
}

.btn-main {
  border-radius: 25px;
  text-decoration: none;
  font-family: 'League Spartan';
  font-weight: 500;
  background-color: #00925E;
  color: white;
  display: block;
  padding: 0.5em;
}

.btn-main:hover {
  background-color: #00734A;
}

input.input-main,
textarea.input-main,
select.input-main {
  background-color: #F2F3FB;
  border: 0.5px solid #D8DAE5;
  border-radius: 10px;
  font-size: 16px;
  font-family: 'League Spartan', sans-serif;
}

input.input-main:hover,
textarea.input-main:hover,
select.input-main:hover {
  /* border: 0.5px solid #CCCCCC !important; */
}

input.input-main::placeholder,
textarea.input-main::placeholder,
select.input-main::placeholder {
  color: #999;
}

input.input-main:focus,
textarea.input-main:focus,
select.input-main:focus {
  border: 0.5px solid #003D6A !important;
}

textarea.input-main {
  height: 100%;
  padding: 5px 10px;
}

select.input-main {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px;
}

label.input-main {
  color: #E00069;
  font-family: 'League Spartan';
  font-size: .8em;
  font-weight: 500;
}


.dropdown-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.3s;
}

.rotate-icon {
  transform: translateY(-50%) rotate(180deg);
  transition: transform 0.3s;
}

.input-search {
  position: absolute;
  top: 0px;
  right: 0;
  width: calc(100% - 20px);
  padding: 8px 10px;
  z-index: 1001;
  background-color: #fff;
  border-radius: 10px;
  border: 0.5px solid #D8DAE5 !important;
  width: 100%;
  min-width: 200px;
  margin: 5px 0px;
}

.dropdown-panel {
  font-family: 'League Spartan', sans-serif;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1001;
}

.options-container {
  -ms-overflow-style: none;
  position: absolute !important;
  top: 50px;
  right: 0px;
  background-color: #F2F3FB;
  border: 0.5px solid #D8DAE5 !important;
  border-radius: 10px;
  color: #003D6A;
  font-family: 'League Spartan', sans-serif;
  min-width: 200px;
  width: 100%;
  z-index: 999;
  max-height: var(--dropdown-max-height);
  overflow-y: auto;
}

.options-container {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #888 #f1f1f1; /* Firefox */
  overflow-y: scroll; /* Show vertical scrollbar */
}

/* Chrome, Edge, and Safari */
.options-container::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 8px; /* Height of the horizontal scrollbar */
}

.options-container::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Track color */
  border-radius: 4px;
}

.options-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Handle color */
  border-radius: 4px;
}

.options-container::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Handle color on hover */
}

.options-container:hover::-webkit-scrollbar-thumb {
  background: #888; /* Show scrollbar handle on hover */
}

/* Hide scrollbar when not scrolling (Webkit) */
.options-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.options-container:hover::-webkit-scrollbar-thumb {
  background-color: #888;
}

/* Optional: Adjust when focused or hovering over the container */
.options-container:focus::-webkit-scrollbar-thumb,
.options-container:hover::-webkit-scrollbar-thumb {
  background-color: #555; /* Darker handle for focus/hover state */
}


/* Mengganti scrollbar untuk Firefox */
.options-container {
  scrollbar-width: thin; /* Lebar scrollbar */
  scrollbar-color: #888 #f1f1f1; /* Warna thumb dan track scrollbar */
}

.options-container div:hover {
  background-color: #f0f0f0;
}

.options-container div {
  color: #003D6A;
  padding: 8px 12px;
  cursor: pointer;
}

.options-container div:not(:first-child) {
  border-top: 0.5px solid #D8DAE5;
}

.options-container div:first-child:hover {
  border-radius: 10px 10px 0 0;
}

.options-container div:last-child:hover {
  border-radius: 10px 10px;
}

.checkbox-option {
  display: flex;
  align-items: center;
}

.checkbox-option input[type="checkbox"] {
  margin-right: 8px;
}

.input-error {
  border: 1px solid #E00069 !important;
}

.input-error .custom-input {
  border: none !important;
}

.input-error::placeholder {
  color: #E00069 !important;
}

/* Existing styles for input, textarea, and select elements */
input.input-main,
textarea.input-main,
select.input-main {
  background-color: #F2F3FB;
  border: 0.5px solid #D8DAE5;
  border-radius: 10px;
  font-size: 16px;
  font-family: 'League Spartan', sans-serif;
}

/* Add hover styles for input, textarea, and select elements */
input.input-main:hover,
textarea.input-main:hover,
select.input-main:hover {
  border-color: #CCCCCC; /* Change border color to red */
  background-color: #EAECFF; /* Optional: change background color to a lighter red for better visibility */
}

/* Maintain focus style */
input.input-main:focus,
textarea.input-main:focus,
select.input-main:focus {
  border: 0.5px solid #003D6A !important;
}

.input-wrap {
  display: flex;
  flex-direction: column;
}

input.input-main, textarea.input-main, select.input-main {
  background-color: #F2F3FB;
  border: 0.5px solid #D8DAE5;
  border-radius: 10px;
  font-size: 16px;
  padding: 8px 35px 8px 8px; /* Normal padding */
  box-sizing: border-box; /* Pastikan perubahan padding tidak mengubah ukuran */
  transition: padding 0.3s ease, border-color 0.3s ease; /* Transisi untuk padding dan border color */
  font-family: 'League Spartan', sans-serif;
}

/* Efek hover yang diperbaiki */
textarea.input-main:hover {
  padding-top: 10px; /* Penyesuaian padding atas */
  padding-bottom: 6px; /* Penyesuaian padding bawah untuk menjaga ukuran total */
  border-color: #CCCCCC; /* Opsi: ubah warna border saat hover */
}

/* Fokus tanpa mengubah ukuran */
input.input-main:focus, textarea.input-main:focus, select.input-main:focus {
  border: 0.5px solid #003D6A !important;
  outline: none; /* Menghapus outline default */
}

</style>