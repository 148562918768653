<template>
    <div>
        <ModalComponent :visible="showModal" @update:visible="updateVisibilityModal">
            <!-- Fill in capital here -->
            <div class="container-modal-small">
                <div class="modal-title">Request a quote</div>
                <div class="border-header"></div>
                <div class="modal-icon">
                    <img class="filter-primary" src="/images/icon/cart.svg" alt="Save Icon" style="height: 100px;">
                </div>
                <div class="color-primary" style="display: flex;justify-content: center;font-size: 1em;">
                    <h1>Ready to request a quote?</h1>
                </div>

                <div style="display: flex;justify-content: center;font-size: 1em;">
                    If you want to change the details of your request after submission, you’ll need to
                </div>
                <div style="display: flex; justify-content: center; font-size: 1em; margin-bottom: 20px">
                    email us at  
                    <a href="mailto:hello@edm8ker.com" style="margin-left: 6px;">
                        hello@edm8ker.com
                    </a>
                </div>

                <div class="modal-button">
                    <button :class="['btn', 'btn-discard', { disabled: buttonsDisabled }]" :disabled="buttonsDisabled" @click="triggerCancel" style="min-width: 100px;">Cancel</button>
                    <button :class="['btn', 'btn-save', { disabled: buttonsDisabled }]" :disabled="buttonsDisabled" @click="triggerConfirm" style="padding: 0.5em 1em; min-width: 100px;">
                        Confirm
                    </button>
                </div>
            </div>
        </ModalComponent>
    </div>
</template>

<script setup>
import { ref, defineExpose, defineEmits } from 'vue';
import ModalComponent from '@/components/ModalComponent.vue';

const showModal = ref(false);
const buttonsDisabled = ref(false);

const emit = defineEmits(['confirm', 'cancel']);

function updateVisibilityModal(visible) {
    showModal.value = visible;
    enableButtons();
}

function triggerConfirm() {
    disableButtons();
    emit('confirm');
    // updateVisibilityModal(false); // Tutup modal setelah konfirmasi
    
}

function triggerCancel() {
    emit('cancel');
    updateVisibilityModal(false); // Tutup modal setelah cancel
}

function disableButtons() {
    buttonsDisabled.value = true;
}

function enableButtons() {
    buttonsDisabled.value = false;
}

defineExpose({
    updateVisibilityModal,
    disableButtons,
    enableButtons
});
</script>

<style>
.btn.disabled {
    background-color: #ccc; /* Warna latar belakang untuk tombol yang dinonaktifkan */
    color: #666; /* Warna teks untuk tombol yang dinonaktifkan */
    cursor: not-allowed; /* Gaya kursor untuk tombol yang dinonaktifkan */
}

.btn-discard.disabled {
    background-color: #e0e0e0; /* Warna khusus untuk tombol discard yang dinonaktifkan */
}

.btn-save.disabled {
    background-color: #a0a0a0; /* Warna khusus untuk tombol save yang dinonaktifkan */
}
</style>
