// src/services/userService.js

import http from "./httpService";

class userService {
  async getProfile(token) {
    const config = {
      headers: {
        Authorization: token,
      },
    };
    return await http.get("/profile", config);
  }

  async getProfileLessonPackages(token, page, limit, favorite) {
    const config = {
      headers: {
        Authorization: token,
      },
    };

    const url =
      favorite === true
        ? `/profile/lesson-packages?page=${page}&limit=${limit}&favorite=true`
        : `/profile/lesson-packages?page=${page}&limit=${limit}`;

    const response = await http.get(url, config);

    if (response.data && response.data.data && response.data.data.rows) {
      response.data.data.rows.forEach((element) => {
        this.setThumbnails(element); // Pemanggilan fungsi helper untuk menetapkan thumbnail
      });
    }

    return response;
  }

  async likeLessonPackage(token, lessonPackageId) {
    const config = {
      headers: {
        Authorization: token,
      },
    };
    const url = `/lesson-packages/like/${lessonPackageId}`;
    return await http.put(url, {}, config); // Menggunakan PUT request dengan body kosong
  }

  setThumbnails(element) {
    const validSubjects = ["ela", "math", "science", "socialstudies"];
    let subjectKey = element.properties.subject
      .toLowerCase()
      .replace(/\s/g, "");

    if (!validSubjects.includes(subjectKey)) {
      element.thumbnail = null;
    } else {
      switch (element.properties.domain.toLowerCase()) {
        case "programming & coding":
          element.thumbnail = `/images/thumbnail/programming-${subjectKey}.png`;
          break;
        case "extended reality (ar/vr/mr)":
          element.thumbnail = `/images/thumbnail/ar-${subjectKey}.png`;
          break;
        case "multimedia and animation":
          element.thumbnail = `/images/thumbnail/multimedia-${subjectKey}.png`;
          break;
        case "design & simulation":
          element.thumbnail = `/images/thumbnail/design-${subjectKey}.png`;
          break;
        case "app development":
          element.thumbnail = `/images/thumbnail/app-dev-${subjectKey}.png`;
          break;
        case "artificial intelligence":
          element.thumbnail = `/images/thumbnail/ai-${subjectKey}.png`;
          break;
        default:
          element.thumbnail = null;
      }
    }
  }

  async getAllUser(name, userIds = [], ymc = true) {
    if (userIds.length > 0) {
      const userIdsParam = userIds.join(",");
      return await http.get(
        `/users?search=${name}&limit=60&user_ids=${userIdsParam}&ymc=true`
      );
    } else {
      return await http.get(`/users?search=${name}&limit=60${ymc ? "&ymc=true" : ""}`);
    }
  }
}

export default new userService();
