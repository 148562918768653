<template>
  <div class="app-bar white-bg">
    <!-- Logo Section -->
    <div class="app-bar-section logo-section">
      <a :href="appLoginUrl">
        <img height="40" src="/images/eddy.svg" alt="Eddy Logo" />
      </a>
    </div>

    <!-- Menu Section -->
    <div class="app-bar-section menu-section" :class="{ 'is-open': mobileMenuOpen }">
      <!-- Links are shown based on device type -->
      <a href="https://eddy4teachers.com/lesson-plans" target="_blank" class="menu-link">Online Making</a>
      <a href="https://eddy4teachers.com/micro-learning" target="_blank" class="menu-link">Professional Development</a>
      <router-link to="/" :class="linkClass('HomePage')" class="menu-link">EdTech Studio</router-link>
      <div class="menu-item menu-link" :class="linkClass('OtherResources')" @mouseenter="openMenu" @mouseleave="closeMenu" @click="toggleSubMenu">
        <a target="_blank">Other Resources</a>
        <div class="submenu" v-show="submenuOpen">
          <a href="https://eddy4teachers.com/community" class="menu-link submenu-link">
            <img src="/images/menu/people.svg" alt="">
            <span class="margin-label">Community</span>
          </a>
          <div tabindex="0" class="submenu-item">
            <a class="menu-link submenu-link" @click="toggleSubSubmenu">
              <img src="/images/menu/ecology.svg" alt="">
              <span class="margin-label">Sustainability Education</span>
            </a>
            <!-- Sub-submenu -->
            <div v-show="showSubSubmenu" class="sub-submenu">
              <div>
                <a href="https://eddy4teachers.com/resources" class="menu-link sub-submenu-link">
                  <img src="/images/menu/folder-favorite.svg" alt="">
                  <span class="margin-label">Resources</span>
                </a>
              </div>
              <div>
                <a href="https://eddy4teachers.com/feedback-and-assesment" class="menu-link sub-submenu-link">
                  <img src="/images/menu/document-text.svg" alt="">
                  <span class="margin-label">Feedback & Assesment</span>
                </a>
              </div>
            </div>
          </div>
          <router-link to="/china-us" class="menu-link submenu-link">
            <img src="/images/menu/medal-star.svg" alt="">
            <span class="margin-label">China-US Young Maker Competition</span>
          </router-link>
          <a href="https://eddy4teachers.com/maker-resources" class="menu-link submenu-link">
            <img src="/images/menu/people.svg" alt="">
            <span class="margin-label">Hands on Making</span>
          </a>
        </div>
      </div>
      <router-link to="/makercart-studio" :class="linkClass('MakerCartPage')" class="menu-link">Makercart Studio</router-link>
    </div>

    <!-- User Section -->
    <div class="app-bar-section user-section">
      <a v-if="!user" style="margin-top: 0.3em;" class="menu-link" @click="promptSignIn">Sign In</a>
      <div v-else>
        <v-menu v-model="userMenuOpen">
          <template v-slot:activator="{ on }">
            <div @click="toggleUserMenu"
              style="font-family: 'League Spartan'; display: flex; align-items: center;font-weight: 500;color: #003D6A;gap: 12px;cursor:pointer"
              v-bind="on">
              <!-- Cek apakah user ada sebelum mencoba menampilkan gambar -->
              <img v-if="user.image" :src="user.image" alt="" style="height: 30px; border-radius: 50%;">
              {{ user.firstname }} {{ user.lastname }}
              <img src="/images/chevron-down.svg" alt="">
            </div>
          </template>
          <v-list>
            <v-list-item>
              <router-link to="/profile">
                <div
                  style="font-family: 'League Spartan'; display: flex; align-items: center;font-weight: 500;color: #003D6A;gap: 12px;cursor:pointer;color: #003D6A">
                  <img src="/images/user-square.svg" style="height: 20px;" alt="">
                  <div style="padding-top: 6px;">Profile</div>
                </div>
              </router-link>
            </v-list-item>
            <v-list-item>
              <div
                style="font-family: 'League Spartan'; display: flex; align-items: center;font-weight: 500;color: #003D6A;gap: 12px;cursor:pointer;color: #E00069"
                @click="signOut">
                <img src="/images/logout.svg" style="height: 20px;" alt="">
                <div style="padding-top: 6px;">Sign Out</div>
              </div>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <!-- Hamburger Menu Icon (only visible on mobile) -->
    <div class="hamburger-menu" @click="toggleMobileMenu" v-if="isMobile">
      <img src="/images/humburger-menu.svg" alt="Menu" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import cookiesService from '@/services/cookiesService';

const user = ref(null);
const userMenuOpen = ref(false);
const mobileMenuOpen = ref(false);
const submenuOpen = ref(false);
const isMobile = ref(window.innerWidth < 991);
const showSubSubmenu = ref(false);
const appLoginUrl = computed(() => process.env.VUE_APP_LOGIN_URL);
const route = useRoute();

const handleResize = () => {
  isMobile.value = window.innerWidth < 991;
};

window.addEventListener('resize', handleResize);

onMounted(() => {
  checkAuthToken();
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});

const checkAuthToken = async () => {
  try {
    const authToken = await cookiesService.getCookie('authToken');
    if (authToken) {
      user.value = authToken;
      clearInterval(intervalId);
    }
  } catch (error) {
    console.error(error);
  }
};

checkAuthToken();

const intervalId = setInterval(checkAuthToken, 500);

function toggleUserMenu() {
  userMenuOpen.value = !userMenuOpen.value;
}

function toggleMobileMenu() {
  mobileMenuOpen.value = !mobileMenuOpen.value;
}

function toggleSubMenu() {
  submenuOpen.value = !submenuOpen.value;
}

function openMenu() {
  if (!isMobile.value) {
    submenuOpen.value = true;
  }
}

function closeMenu() {
  if (!isMobile.value) {
    submenuOpen.value = false;
  }
}

function signOut() {
  cookiesService.removeCookie('authToken');
  window.location.href = `${process.env.VUE_APP_LOGIN_URL}`;
}

function promptSignIn(event) {
  event.preventDefault();
  window.location.href = `${process.env.VUE_APP_LOGIN_URL}/request-login`;
}

function toggleSubSubmenu() {
  showSubSubmenu.value = !showSubSubmenu.value;
}

// Nama-nama route yang merupakan child dari "Other Resources"
const otherResourcesRoutes = ['ChinaUSPage', 'CompetitionDetailsPage', 'ChinaUSFAQPage', 'DiscussionDetailsPage', 'SubmissionPage', 'SubmissionSubmitPage', 'SubmissionListPage', 'SubmissionDetailPage', 'SubmissionReviewPage'];

function linkClass(name) {
  if (name === 'OtherResources' && otherResourcesRoutes.includes(route.name)) {
    return 'menu-link-active';
  }
  return route.name === name ? 'menu-link-active' : '';
}
</script>

<style>
.app-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  box-shadow: none;
  border-bottom: 1px solid #D8DAE5;
  background-color: white;
}

.app-bar-section {
  display: flex;
  align-items: center;
}

.logo-section {
  flex-grow: 1;
}

.hamburger-menu {
  display: none;
  /* Hidden on desktop */
  cursor: pointer;
}

.menu-section {
  flex-grow: 8;
  justify-content: center;
  gap: 0.8em;
  position: relative;
}

.user-section {
  flex-grow: 2;
  justify-content: end;
}

.menu-link {
  text-decoration: none;
  color: #003D6A;
  font-weight: 500;
  font-family: 'League Spartan';
  font-size: 0.95em;
  cursor: pointer;
  padding: 7px 10px;
  border-bottom: 3px solid transparent;
  transition: color 0.3s, border-color 0.3s;
}

.menu-link-active {
  color: #E00069;
  border-bottom-color: #E00069;
}

.submenu {
  display: none;
  position: absolute;
  background-color: white;
  padding: 12px 16px;
  border-radius: 25px;
  z-index: 1;
}

.menu-link:hover + .submenu, .submenu:hover {
  display: block;
}

.sub-submenu {
  display: block;
  padding-left: 20px;
}

.submenu-link {
  display: flex;
  gap: 5px
}

.sub-submenu-link {
  display: flex;
  gap: 10px  
}

/* Mobile Styles */
@media (max-width: 991px) {
  .hamburger-menu {
    display: flex;
  }

  .menu-section {
    display: none;
  }

  .menu-section.is-open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 56px;
    left: 0;
    width: 100%;
    padding: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: white;
  }

  .menu-link {
    padding: 10px 15px;
  }

  .submenu,
  .sub-submenu {
    position: static;
  }

  .v-menu {
    left: 60% !important;
    top: 8%;
  }

  .menu-item:hover .submenu {
    display: '';
    position: absolute;
  }
}

.v-menu {
  left: 90%;
  top: 8%;
}

.user-section .v-menu__content {
  top: 100% !important;
}

.menu-item:hover .submenu {
  display: block;
}

.margin-label {
  margin-top: 0.23em;
}

.greyed-out {
  color: grey;
  pointer-events: none;
  cursor: default;
  position: relative;
}

.small-icon {
  width: 65px;
  height: 10px;
  position: absolute;
  top: 0;
  margin-right: 5px;
  margin-top: 5px;
}
</style>
