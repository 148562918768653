// src/services/httpService.js
import axios from 'axios';
import cookiesService from '@/services/cookiesServiceNoAwait';

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL + '/api',
  headers: {
    'Content-type': 'application/json',
  },
});

http.interceptors.request.use((config) => {
  const authToken = cookiesService.getCookie('authToken');
  if (authToken?.token) {
    config.headers['Authorization'] = authToken.token;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

http.interceptors.response.use(response => {
  return response;
}, error => {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    if (originalRequest.url.includes('/china-us/submission/form')) {
      return Promise.reject(error);
    }
    window.location = `${process.env.VUE_APP_LOGIN_URL}/request-login?ref_path=${encodeURIComponent(window.location.pathname)}`;
  }
  return Promise.reject(error);
});

// Tambahkan metode untuk membangun URL dengan query params
http.getUrlWithParams = (url, params) => {
  if (!params || Object.keys(params).length === 0) {
    return url;
  }
  
  const queryString = Object.keys(params)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
    .join('&');
  
  return `${url}?${queryString}`;
};

export default http;

