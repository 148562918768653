<template>
  <HeroSection v-if="showHeroSection"></HeroSection>
  <v-container>
    <PhaseComponent :selectedNumber="generatePhase" @phase-clicked="handlePhaseClick"></PhaseComponent>
    <div class="form-title-mini mt-4">Generate a tech-infused lesson package for the topic you’re teaching</div>
    <PromptForm ref="promptFormRef" :disableButton="generateButtonDisabled" @generate-clicked="generateLessonPackage">
    </PromptForm>
  </v-container>
  <v-container>
    <GenerateSection :showPromptPreview="showPromptPreview" :showErrorSection="showErrorGenerateSection"
      :showLoading="showLoadingGenerateSection" :showResultSection="showResultGenerateSection" @generate-clicked="generateLessonPackage">
    </GenerateSection>
  </v-container>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import HeroSection from '@/components/HeroSection.vue';
import GenerateSection from '@/components/GenerateSection.vue';
import PhaseComponent from '@/components/PhaseComponent.vue';
import PromptForm from '@/components/PromptForm.vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
// import { useRouter } from 'vue-router';
// eslint-disable-next-line
import LessonPackageService from '@/services/lessonPackageService';
import LocalStorageService from '@/services/localStorageService';

const store = useStore();
const route = useRoute();
// const router = useRouter();

const generatePhase = ref(1);
const showGenerateSection = ref(false);
const showHeroSection = ref(true);
const promptFormRef = ref(null);
const generateButtonDisabled = ref(false);

const showLoadingGenerateSection = ref(false);
const showResultGenerateSection = ref(true);
const showPromptPreview = ref(false);
const showErrorGenerateSection = ref(false);

onMounted(async () => {
  generateButtonDisabled.value = false;
  setGeneratePhase(1);

  const receivedValue = route.query.key;
  
  if (receivedValue === 'reset') {
    resetState();
  } else if (receivedValue === 'step-2') {
    const storedData = store.state.formResult;

    if (storedData !== null && storedData !== undefined) {
      const formResults = LocalStorageService.getFormResults();
      const formData = LocalStorageService.getFormData();
      if (formResults && formData) {
        store.commit('SET_FORM_RESULTS', formResults);
        store.commit('SET_FORM_DATA', formData);
      } else {
        resetState();
        return;
      }
    }

    setGeneratePhase(2);
    showErrorGenerateSection.value = false;
    showGenerateSection.value = true;
    showHeroSection.value = false;

    showLoadingGenerateSection.value = true;
    showPromptPreview.value = true;

    showLoadingGenerateSection.value = false;
  } else {
    resetState();
  }

  let cacheGenerate = await LocalStorageService.getCache('cache-generate');

  if(cacheGenerate) {
    LocalStorageService.setFormResults(cacheGenerate.formResult);
    store.commit('SET_FORM_RESULTS', cacheGenerate.formResult);
    LocalStorageService.setFormData(cacheGenerate.formData);
    store.commit('SET_FORM_DATA', cacheGenerate.formData);

    console.log("cacheGenerate.formData", cacheGenerate.formData)

    setGeneratePhase(2);
    showErrorGenerateSection.value = false;
    showGenerateSection.value = true;
    showHeroSection.value = false;

    showLoadingGenerateSection.value = true;
    showPromptPreview.value = true;

    showLoadingGenerateSection.value = false;
        
    // let jsonString = JSON.stringify(cacheGenerate.selectedLessonPackage);
    
    // let encryptedString = encodeURIComponent(jsonString);
    
    // router.push('/preview?data=' + encryptedString);
  }
});

// eslint-disable-next-line
const generateLessonPackage = async (data) => {
  try {
    setGeneratePhase(2);
    showErrorGenerateSection.value = false;
    showGenerateSection.value = true;
    showHeroSection.value = false;
    generateButtonDisabled.value = true;

    showPromptPreview.value = true;

    // eslint-disable-next-line 
    LocalStorageService.setFormResults([]);
    store.commit('SET_FORM_RESULTS', []);

    // First call
    showLoadingGenerateSection.value = true;
    let response = await LessonPackageService.generateLessonPackage(data);
    if (response.status === 200) {
      // downloadJSON(response, 'result1.json');
      showLoadingGenerateSection.value = false;
      data.resource_id = response.data.data.id;

      LocalStorageService.setFormResults(response.data.data);
      store.commit('SET_FORM_RESULTS', response.data.data);
    } else {
      throw new Error(`First request failed with status ${response.status}`);
    }

    // Add delay before Second call
    await new Promise(resolve => setTimeout(resolve, 5000)); // Delay for 5 seconds

    // Second call
    showLoadingGenerateSection.value = true;
    response = await LessonPackageService.generateLessonPackage(data);
    if (response.status === 200) {
      // downloadJSON(response, 'result2.json');
      showLoadingGenerateSection.value = false;

      LocalStorageService.setFormResults(response.data.data);
      store.commit('SET_FORM_RESULTS', response.data.data);    
    } else {
      throw new Error(`Second request failed with status ${response.status}`);
    }

    // Add delay before Third call
    await new Promise(resolve => setTimeout(resolve, 5000)); // Delay for 5 seconds

    // Third call
    showLoadingGenerateSection.value = true;
    response = await LessonPackageService.generateLessonPackage(data);
    if (response.status === 200) {
      // downloadJSON(response, 'result3.json');
      showLoadingGenerateSection.value = false;

      LocalStorageService.setFormResults(response.data.data);
      store.commit('SET_FORM_RESULTS', response.data.data);    
    } else {
      throw new Error(`Third request failed with status ${response.status}`);
    }

    // Process successful response
    generateButtonDisabled.value = false;
    

  } catch (error) {
    console.error('Error generating lesson package:', error);
    generateButtonDisabled.value = false;
    showLoadingGenerateSection.value = false;
    showErrorGenerateSection.value = true;
  }
};
  
// eslint-disable-next-line
function downloadJSON(data, filename = 'download.json') {
  const jsonString = JSON.stringify(data);
  const blob = new Blob([jsonString], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  
  // Membuat link untuk download
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  
  // Membersihkan dan menghapus URL dan link
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

// eslint-disable-next-line
const generateDummy = async (data) => {
  try {
    setGeneratePhase(2);
    showErrorGenerateSection.value = false;
    showGenerateSection.value = true;
    showHeroSection.value = false;
    generateButtonDisabled.value = true;

    showPromptPreview.value = true;

    // let allResults = []; // Initialize an empty array to store results from all calls
    LocalStorageService.setFormResults([]);
      store.commit('SET_FORM_RESULTS', []);
    // First call
    showLoadingGenerateSection.value = true;
    let responseData = await fetch('http://localhost:8080/dummy/lesson1.json');
    let response = await responseData.json();
    
    if (response.status === 200) {
      showLoadingGenerateSection.value = false;
      // allResults = allResults.concat(response.data.data.results); // Concatenate the results of the first call
      data.resource_id = response.data.data.id;

      LocalStorageService.setFormResults(response.data.data);
      store.commit('SET_FORM_RESULTS', response.data.data);
    } else {
      throw new Error(`First request failed with status ${response.status}`);
    }

    // Add delay before Second call
    await new Promise(resolve => setTimeout(resolve, 5000)); // Delay for 5 seconds

    // Second call
    showLoadingGenerateSection.value = true;
    
    responseData = await fetch('http://localhost:8080/dummy/lesson2.json');
    response = await responseData.json();
    if (response.status === 200) {
      showLoadingGenerateSection.value = false;
      // allResults = allResults.concat(response.data.data.results); // Concatenate the results of the second call

      LocalStorageService.setFormResults(response.data.data);
      store.commit('SET_FORM_RESULTS', response.data.data);    
    } else {
      throw new Error(`Second request failed with status ${response.status}`);
    }

    // Add delay before Third call
    await new Promise(resolve => setTimeout(resolve, 5000)); // Delay for 5 seconds

    // Third call
    showLoadingGenerateSection.value = true;

    responseData = await fetch('http://localhost:8080/dummy/lesson3.json');
    response = await responseData.json();
    if (response.status === 200) {
      showLoadingGenerateSection.value = false;
      // allResults = allResults.concat(response.data.data.results); // Concatenate the results of the third call

      LocalStorageService.setFormResults(response.data.data);
      store.commit('SET_FORM_RESULTS', response.data.data);    
    } else {
      throw new Error(`Third request failed with status ${response.status}`);
    }

    // Process successful response
    generateButtonDisabled.value = false;
    // LocalStorageService.setFormResults({ results: allResults }); // Save the concatenated results
    // store.commit('SET_FORM_RESULTS', { results: allResults });

  } catch (error) {
    console.error('Error generating lesson package:', error);
    generateButtonDisabled.value = false;
    showLoadingGenerateSection.value = false;
    showErrorGenerateSection.value = true;
  }
};

const handlePhaseClick = (phaseNumber) => {
  console.log('phaseNumber', phaseNumber);
  if (phaseNumber === 1) {
    resetState();
  }
};

const setGeneratePhase = (newPhase) => {
  generatePhase.value = newPhase;
};

const resetState = async () => {
  showErrorGenerateSection.value = false;
  generatePhase.value = 1;
  showHeroSection.value = true;
  showLoadingGenerateSection.value = false;
  showPromptPreview.value = false;

  if (promptFormRef.value && promptFormRef.value.resetForm) {
    promptFormRef.value.resetForm();
  }

  LocalStorageService.resetFormData();
  LocalStorageService.resetFormResults();
  store.commit('SET_FORM_RESULTS', []);

  if (window.location.search) {
    const url = new URL(window.location);
    
    url.search = "";
    
    window.history.pushState({}, '', url);
  }
};

</script>