import { createRouter, createWebHistory } from "vue-router";
import { isAdmin } from "./middlewares";
import LoginPage from "../views/LoginPage.vue";
import HomePage from "../views/HomePage.vue";
import PreviewPage from "../views/PreviewPage.vue";
import EditPage from "../views/EditPage.vue";
import PrintPage from "../views/PrintPage.vue";
import ProfilePage from "../views/ProfilePage.vue";
import LessonPackagePage from "../views/LessonPackagePage.vue";
import cookiesService from "@/services/cookiesService"; // Import service
import AuthService from "@/services/authService"; // Import service
import ChinaUSPage from "../views/ChinaUs/ChinaUSPage.vue";
import FAQPage from "../views/ChinaUs/FAQPage.vue";
import CompetitionDetailsPage from "../views/ChinaUs/CompetitionDetailsPage.vue";
import DiscussionPage from "../views/ChinaUs/DiscussionPage.vue";
import DiscussionDetailsPage from "../views/ChinaUs/DiscussionDetailsPage.vue";
import SubmissionPage from "../views/ChinaUs/SubmissionPage.vue";
import SubmissionSubmitPage from "../views/ChinaUs/SubmissionSubmitPage.vue";
import SubmissionListPage from "../views/ChinaUs/SubmissionListPage.vue";
import SubmissionDetailPage from "../views/ChinaUs/SubmissionDetailPage.vue";
import SubmissionReviewPage from "../views/ChinaUs/SubmissionReviewPage.vue";
import ChinaUSAdminPage from "../views/Admin/ChinaUSAdminPage.vue";
import ChinaUSProjectDetailPage from "../views/Admin/ChinaUSProjectDetailPage.vue";
import MakerCartPage from "../views/MakerCart/MakerCartPage.vue";

import LessonPlansListPage from "../views/Admin/LessonPlans/ListPage.vue";
import LessonPlansFormPage from "../views/Admin/LessonPlans/FormPage.vue";

import UsersListPage from "../views/Admin/Users/ListPage.vue";
import UsersFormPage from "../views/Admin/Users/FormPage.vue";

import NoAuthPage from "../views/NoAuthPage.vue";
import AdminLayout from "@/layouts/AdminLayout.vue"; // Import AdminLayout
import DefaultLayout from "@/layouts/DefaultLayout.vue"; // Import DefaultLayout
import EmptyLayout from "@/layouts/EmptyLayout.vue"; // Import EmptyLayout
import { RouterView } from 'vue-router'; // Import RouterView

const routes = [
  {
    path: "/login",
    component: EmptyLayout,
    children: [
      {
        path: "",
        name: "LoginPage",
        component: LoginPage,
        meta: { requiresAuth: false, title: "Login" },
      }
    ]
  },
  {
    path: "/",
    component: DefaultLayout,
    children: [
      {
        path: "",
        name: "HomePage",
        component: HomePage,
        meta: { requiresAuth: true, title: "Home" },
      },
      {
        path: "preview",
        name: "PreviewPage",
        component: PreviewPage,
        meta: { requiresAuth: true, page: "preview", title: "Preview" },
      },
      {
        path: "profile",
        name: "ProfilePage",
        component: ProfilePage,
        meta: { requiresAuth: true, title: "Profile" },
      },
      {
        path: "lesson-packages/:id",
        name: "LessonPackagePage",
        component: LessonPackagePage,
        meta: { requiresAuth: true, page: "preview", title: "Lesson Package" },
        props: true,
      },
      {
        path: "edit-packages/:id",
        name: "EditPage",
        component: EditPage,
        meta: { requiresAuth: true, page: "edit", title: "Edit Package" },
      }
    ]
  },
  {
    path: "/print/:id",
    component: EmptyLayout,
    children: [
      {
        path: "",
        name: "PrintPage",
        component: PrintPage,
        meta: {
          requiresAuth: false,
          header: "hidden",
          footer: "hidden",
          page: "print",
          title: "Print",
        },
      }
    ]
  },
  {
    path: "/admin",
    component: AdminLayout,
    meta: { requiresAuth: true },
    beforeEnter: async () => {
      const allowed = await isAdmin();

      if (!allowed) {
        return { path: "/" };
      }
    },
    children: [
      {
        path: 'china-us',
        component: RouterView, // Gunakan RouterView di sini
        children: [
          {
            path: '',
            name: 'ChinaUSAdminPage',
            component: ChinaUSAdminPage,
            meta: { requiresAuth: true, title: "China US Admin" },
          },
          {
            path: 'projects/:id',
            name: 'ChinaUSProjectDetailPage',
            component: ChinaUSProjectDetailPage,
            meta: { requiresAuth: true, title: "China US Project Detail" },
          },
        ]
      },
      {
        path: 'lesson-plans',
        component: RouterView, // Gunakan RouterView di sini
        children: [
          {
            path: '',
            name: 'LessonPlansListPage',
            component: LessonPlansListPage,
            meta: { requiresAuth: true, title: "Lesson Plan" },
          },
          {
            path: 'form',
            name: 'LessonPlansFormPage',
            component: LessonPlansFormPage,
            meta: { requiresAuth: true, title: "Form Lesson Plan" },
          },
        ]
      },
      {
        path: 'users',
        component: RouterView, // Gunakan RouterView di sini
        children: [
          {
            path: '',
            name: 'UsersListPage',
            component: UsersListPage,
            meta: { requiresAuth: true, title: "Lesson Plans" },
          },
          {
            path: 'form',
            name: 'UsersFormPage',
            component: UsersFormPage,
            meta: { requiresAuth: true, title: "Form Lesson Plans" },
          },
        ]
      },
    ]
  },
  {
    path: "/china-us",
    component: DefaultLayout,
    meta: { requiresAuth: false },
    children: [
      {
        path: '',
        name: "ChinaUSPage",
        component: ChinaUSPage,
        meta: { requiresAuth: false, title: "China US" },
      },
      {
        path: 'faq',
        name: 'ChinaUSFAQPage',
        component: FAQPage,
        meta: { requiresAuth: false, title: "FAQ" },
      },
      {
        path: 'competition-details',
        name: 'CompetitionDetailsPage',
        component: CompetitionDetailsPage,
        meta: { requiresAuth: false, title: "Competition Details" },
      },
      {
        path: 'discussion',
        name: 'DiscussionPage',
        component: DiscussionPage,
        meta: { requiresAuth: true, title: "Discussion" },
      },
      {
        path: 'discussion/:id',
        name: 'DiscussionDetailsPage',
        component: DiscussionDetailsPage,
        meta: { requiresAuth: true, title: "Discussion Details" },
      },
      {
        path: 'submission',
        name: 'SubmissionPage',
        component: SubmissionPage,
        meta: { requiresAuth: false, title: "Submission" },
      },
      {
        path: 'submission-submit',
        name: 'SubmissionSubmitPage',
        component: SubmissionSubmitPage,
        meta: { requiresAuth: true, title: "Submit Submission" },
      },
      {
        path: 'submission-list',
        name: 'SubmissionListPage',
        component: SubmissionListPage,
        meta: { requiresAuth: false, title: "Submission List" },
      },
      {
        path: 'submission-detail/:id',
        name: 'SubmissionDetailPage',
        component: SubmissionDetailPage,
        meta: { requiresAuth: false, title: "Submission Detail" },
      },
      {
        path: 'submission-review/:id',
        name: 'SubmissionReviewPage',
        component: SubmissionReviewPage,
        meta: { requiresAuth: false, title: "Submission Review" },
      }
    ]
  },
  {
    path: "/makercart-studio",
    component: DefaultLayout,
    meta: { requiresAuth: false },
    children: [
      {
        path: '',
        name: "MakerCartPage",
        component: MakerCartPage,
        meta: { requiresAuth: false, title: "Maker Cart" },
      }
    ]
  },
  {
    path: "/403",
    name: "NoAuthPage",
    component: NoAuthPage,
    meta: { requiresAuth: false, title: "No Authorization" },
  },
  // ... add another route here
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

let tokenValidationTimeout;

async function validateTokenPeriodically() {
  clearTimeout(tokenValidationTimeout);

  try {
    const token = await cookiesService.getCookie("authToken");
    if (!token) {
      // window.location = `${process.env.VUE_APP_LOGIN_URL}/request-login`;
      return;
    }

    const validationResponse = await AuthService.validateToken(token.token);

    if (!validationResponse) {
      // window.location = `${process.env.VUE_APP_LOGIN_URL}/request-login`;
    } else {
      tokenValidationTimeout = setTimeout(validateTokenPeriodically, 30000);
    }
  } catch (error) {
    console.error("Error during token validation:", error);
  }
}

router.beforeEach(async (to, from, next) => {
  const authToken = await cookiesService.getCookie("authToken");

  if (to.name === "SubmissionSubmitPage") {
    const ymc_is_open = true;

    if (!ymc_is_open) {
      next({ name: "SubmissionPage" });
    }
  }

  if (to.meta.requiresAuth && !authToken) {
    await checkAuthentication(next(), authToken);
  } else {
    if (to.name === 'SubmissionPage') {
      const url = new URL(window.location);

      if (url.searchParams.has("token")) {
        checkAuthentication(next, authToken);
      }
    } else if (to.name === 'MakerCartPage') {
      const url = new URL(window.location);

      if (url.searchParams.has("token")) {
        checkAuthentication(next, authToken);
      }
    } else if (to.name === 'SubmissionListPage') {
      const url = new URL(window.location);

      if (url.searchParams.has("token")) {
        checkAuthentication(next, authToken);
      }
    } else if(to.name === 'SubmissionSubmitPage') {
      const url = new URL(window.location);

      if (url.searchParams.has("token")) {
        checkAuthentication(next, authToken);
      }
    }

    next();
  }
});

// eslint-disable-next-line 
async function checkAuthentication(next, authToken) {
  if (window.location.search) {
    const url = new URL(window.location);

    if (url.searchParams.has("token")) {
      const token = url.searchParams.get("token");
      try {
        const result = await AuthService.validateToken(token);
        if (result.data) {
          result.data.token = token;
          const actualToken = result.data;
          await cookiesService.setCookie("authToken", actualToken, {
            /* options */
          });
          next();
        } else {
          window.location = `${process.env.VUE_APP_LOGIN_URL}/request-login`;
        }
      } catch (error) {
        console.error("Terjadi kesalahan saat validasi token:", error);
      }
    } else if (url.searchParams.has("key")) {
      // eslint-disable-next-line
    } else {
      // window.location = `${process.env.VUE_APP_LOGIN_URL}/request-login`;
    }

    url.search = "";
    window.history.pushState({}, "", url);

    if (typeof tokenValidationTimeout === 'undefined') {
      validateTokenPeriodically();
    }
  }
}

router.afterEach((to) => {
  const defaultTitle = "Builder - the Educator's Buddy";
  document.title = `${to.meta.title} - the Educator's Buddy` || defaultTitle;
});

export default router;
