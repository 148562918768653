<template>
  <aside class="sidebar" :class="{ 'mobile-open': props.mobileMenuOpen }">
    <div class="sidebar-header">
      <img height="40" src="/images/eddy.svg" alt="Eddy Logo" />
      <div class="close-menu" @click="$emit('toggle-mobile-menu')" v-if="isMobile">
        <img src="/images/close.svg" alt="Close" />
      </div>
    </div>
    <nav class="menu scrollbar" id="style-4">
      <ul>
        <li
          v-for="item in items"
          :key="item.title"
          @click="navigate(item)"
          :class="{ highlight: isActive(item) }"
        >
          <img height="20" :src="`/images/sidebar-menu/${item.icon}.svg`" alt="Eddy Logo" />
          <span style="margin: auto 0;">{{ item.title }}</span>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, defineProps } from 'vue';
import { useRouter, useRoute } from 'vue-router';

const props = defineProps({
  mobileMenuOpen: Boolean
});

const router = useRouter();
const route = useRoute();

const appLoginUrl = computed(() => process.env.VUE_APP_LOGIN_URL);

const items = ref([
  { title: 'Dashboard', icon: 'dashboard', url: `${appLoginUrl.value}/admin` },
  { title: 'Users List', icon: 'user-list', route: '/admin/users' },
  { title: 'Redeemed Referrals', icon: 'redeemed-referrals', url: `${appLoginUrl.value}/admin/referrals` },
  { title: 'Register New Teacher', icon: 'register-new-teacher', url: `${appLoginUrl.value}/admin/register-new-user` },
  { title: 'Lesson Plan', icon: 'lesson-plan', route: '/admin/lesson-plans' },
  { title: 'Maker Resources', icon: 'maker-resources', url: `${appLoginUrl.value}/admin/maker-resources` },
  { title: 'Micro Learning', icon: 'micro-learning', url: `${appLoginUrl.value}/admin/instructional-strategies` },
  { title: 'China-US YMC', icon: 'china-us-ymc', route: '/admin/china-us', class: 'highlight' },
  { title: 'YSA Dashboard', icon: 'ysa-dashboard', url: `${appLoginUrl.value}/admin/ysa/dashboard` },
  { title: 'Categories', icon: 'categories', url: `${appLoginUrl.value}/admin/taxonomy` },
  { title: 'Comments', icon: 'comments', url: `${appLoginUrl.value}/admin/comments` },
  { title: 'Changelog', icon: 'changelog', url: `${appLoginUrl.value}/admin/changelog` },
  { title: 'Coach', icon: 'coach', url: `${appLoginUrl.value}/admin/coach` },
  { title: 'Users Log', icon: 'users-log', url: `${appLoginUrl.value}/admin/logs/user` },
  { title: 'Content Logs', icon: 'content-logs', url: `${appLoginUrl.value}/admin/logs/post` },
  { title: 'Scorm Files', icon: 'scorm-files', url: `${appLoginUrl.value}/admin/scorm` },
  { title: 'Enquiries', icon: 'enquiries', url: `${appLoginUrl.value}/admin/form-submission` },
]);

const navigate = (item) => {
  if (item.url) {
    window.open(item.url, '_blank');
  } else if (item.route) {
    router.push(item.route);
  }
};

const isMobile = ref(window.innerWidth < 991);

const handleResize = () => {
  isMobile.value = window.innerWidth < 991;
};

const isActive = (item) => {
  if (item.route) {
    return route.path.startsWith(item.route);
  } else if (item.url) {
    return route.fullPath.startsWith(item.url);
  }
  return false;
};

window.addEventListener('resize', handleResize);

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style scoped>
.sidebar {
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  color: #003D6A;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  border-right: 1px solid #D8DAE5;
}

.sidebar-header {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.logo {
  max-width: 100px;
}

.menu {
  flex: 1;
  margin: 0px 0px 0px 20px;
}

.menu ul {
  list-style: none;
  padding: 0;
}

.menu li {
  padding: 10px;
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  border-radius: 12px;
  margin-bottom: 2px;
}

.menu li:hover {
  background-color: #E00069;
  color: white;
}

.menu li:hover img {
  filter: invert(100%) sepia(0%) saturate(7482%) hue-rotate(161deg) brightness(124%) contrast(91%);
}

.menu li img {
  margin-right: 10px;
  color: #003D6A;
  filter: invert(15%) sepia(77%) saturate(2258%) hue-rotate(186deg) brightness(92%) contrast(104%);
}

.menu .highlight img {
  filter: invert(98%) sepia(8%) saturate(59%) hue-rotate(140deg) brightness(119%) contrast(100%);
}

.highlight {
  background-color: #E00069;
  color: white;
}

/* Mobile Styles */
@media (max-width: 991px) {
  .sidebar {
    width: 250px;
    height: 100%;
    position: fixed;
    z-index: 1000;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }

  .sidebar-header {
    display: flex;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 20px;
  }

  .sidebar.mobile-open {
    transform: translateX(0);
  }

  .close-menu {
    display: block;
    cursor: pointer;
  }

  .close-menu img {
    height: 30px;
  }
}
</style>
