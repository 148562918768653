<template>
  <div>
    <div class="flex-container">
      <div class="flex-item" style="padding: 0">
        <div id="dynamicHeightDiv" class="pa-5 background-style hero-section-3">
          <div class="label-cover" style="text-align: center;">
            <div class="blue-backdrop">Makercart Studio</div>
          </div>
        </div>
      </div>
    </div>

    <div class="content-container-maker-cart">
      <div class="search-bar-maker-cart">
        <span class="search-icon"><img src="/images/maker-cart/union.svg" alt="Search icon" /></span>
        <input class="input-main" type="text" v-model="searchQuery" @input="debouncedSearch" placeholder="Search" />
        <button class="btn-main" @click="performSearch" style="padding: 0px 20px; border-radius: 15px; height: 100%;">Go</button>
      </div>
    </div>

    <div class="content-container-maker-cart" style="justify-content: flex-end;">
      <div style="display: flex;align-items: center;justify-content: end;gap:5px">
        <button class="button-white ml-2" @click="updateVisibilityModalUserCart(true)" style="cursor: pointer;">
          <img height="20" src="/images/icon/cart.svg" alt="Cart Button">
          Check your cart <b>{{ cartCount }} Selected(s)</b>
        </button>
      </div>
    </div>

    <div class="content-container-maker-cart">
      <AsideComponent :selectedFilters="selectedFilters" @sortChanged="onSortChanged" @itemSelected="onItemSelected" />
      <main style="width: 100%">
        <div v-if="isLoading" class="loading-state">
          <div class="spinner-container">
            <div class="mt-5 spinner"></div>
          </div>
        </div>

        <div v-else class="item-list-maker-cart">
          <div v-if="items.length === 0" class="no-items-message">
            <div class="text-center py-5">
              <img src="/images/maker-cart/not-yet-blue.svg" alt="No Results" class="responsive-image-submission-list">
              <div class="text-center color-primary text-medium-bold">Oh no! We didn't find any results for {{ searchQuery }}.</div>
              <div class="text-center color-primary" style="font-size: 0.8em;">Try again by removing the filter and see what's the results are.</div>
            </div>
          </div>
          <div v-else class="item-grid-container">
            <ItemMakerCart v-for="item in items" :key="item.makercart_id" :item="item" @item-clicked="updateVisibilityModalMakerCartDetail(true, item.makercart_id)" />
          </div>
        </div>
      </main>
    </div>

    <div class="pagination-controls mb-5">
      <img src="/images/chinaus/vector-right.svg" class="mr-2" alt="" @click="changePage(currentPage - 1)">
      <button v-for="page in totalPages" :key="page" :class="{ 'page-active': page === currentPage }" @click="changePage(page)">
        {{ page }}
      </button>
      <img src="/images/chinaus/vector-left.svg" class="ml-2" alt="" @click="changePage(currentPage + 1)">
    </div>

    <MakerCartDetail ref="makerCartDetail"></MakerCartDetail>
    <UserCart 
      ref="userCart" 
      @update:confirmation="updateVisibilityModalConfirmation" 
      @refreshCart="updateCartCount"
      @triggerUpdateVisibilityModalSuccess="updateVisibilityModalSuccess"
      @triggerUpdateVisibilityModalConfirmation="updateVisibilityModalConfirmation"
    ></UserCart>
    <ConfirmationModal ref="confirmationModal" @confirm="handleConfirm" @cancel="handleCancel"></ConfirmationModal>
    <SuccessModal ref="successModal"></SuccessModal>
    <LoginModal ref="loginModal"></LoginModal>
    <MinimumRequestModal ref="minimumRequestModal"></MinimumRequestModal>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import AsideComponent from './AsideComponent.vue';
import ItemMakerCart from '@/components/maker-cart/ItemMakerCart.vue';
import MakerCartDetail from '@/components/maker-cart/MakerCartDetail.vue';
import UserCart from '@/components/maker-cart/UserCart.vue';
import ConfirmationModal from '@/components/maker-cart/ConfirmationModal.vue';
import MinimumRequestModal from '@/components/maker-cart/MinimumRequestModal.vue';
import SuccessModal from '@/components/maker-cart/SuccessModal.vue';
import LoginModal from '@/components/maker-cart/LoginModal.vue';
import StemStudioService from '@/services/stemStudioService';
import { debounce } from 'lodash';
import cookiesService from '@/services/cookiesService';

const searchQuery = ref('');
const selectedFilters = ref([]);
const selectedSort = ref('newest');
const items = ref([]);
const activeItems = ref([]);
const isLoading = ref(true);

const makerCartDetail = ref(null); 
const userCart = ref(null);
const confirmationModal = ref(null);
const successModal = ref(null);
const loginModal = ref(null);
const minimumRequestModal = ref(null);

const currentPage = ref(1);
const totalItems = ref(0);
const totalPages = ref(0);

const cartCount = ref(0);

function changePage(page) {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
}

const performSearch = () => {
  currentPage.value = 1;
  getAll({ search: searchQuery.value });
};

const debouncedSearch = debounce(performSearch, 300);

const getAll = async (params = {}) => {
  try {
    isLoading.value = true;
    const filters = {};

    if (activeItems.value.grades) {
      filters.grades = activeItems.value.grades.map(item => item.title);
      // Continue with your logic for grades
    } else {
      console.log("Property 'grades' is missing in activeItems.value");
    }

    if (activeItems.value.hardware) {
      filters.hardware = activeItems.value.hardware.map(item => item.title);
      // Continue with your logic for hardware
    } else {
      console.log("Property 'hardware' is missing in activeItems.value");
    }

    if (activeItems.value.prototypingMaterials) {
      filters.prototypingMaterials = activeItems.value.prototypingMaterials.map(item => item.title);
      // Continue with your logic for prototypingMaterials
    } else {
      console.log("Property 'prototypingMaterials' is missing in activeItems.value");
    }

    if (activeItems.value.subjects) {
      filters.subjects = activeItems.value.subjects.map(item => item.title);
      // Continue with your logic for subjects
    } else {
      console.log("Property 'subjects' is missing in activeItems.value");
    }
    
    const response = await StemStudioService.getAll({ 
      page: currentPage.value, 
      limit: 6, 
      sort_by: selectedSort.value, 
      ...filters, 
      ...params 
    });
    
    if (response.status === 200) {
      items.value = response.data.data.rows.map(item => ({
        ...item,
        image: item.image_thumbnail && item.image_thumbnail.length > 0 ? item.image_thumbnail[0] : ''
      }));

      totalItems.value = response.data.data.total_rows;
      totalPages.value = response.data.data.total_pages;
    }
  } catch (error) {
    console.log(error.message);
  } finally {
    isLoading.value = false;
  }
};


watch([currentPage, searchQuery], () => getAll({ search: searchQuery.value }));

const onSortChanged = (sortParam) => {
  selectedSort.value = sortParam;
  currentPage.value = 1;
  getAll({ sort_by: selectedSort.value });
};

onMounted(() => {
  adjustHeight();
  setBackgroundImage();
  getAll();
  updateCartCount(); // Update cart count on mounted

  const interval = setInterval(updateCartCount, 1000); // Update cart count every second

  onBeforeUnmount(() => {
    clearInterval(interval); // Clear interval when component is unmounted
  });
});

async function postCartData() {
  try {
    const storedCart = localStorage.getItem('cartItems');
    
    if (storedCart) {
      const cartItems = JSON.parse(storedCart);

      console.log("cartItems", cartItems);

      const data = {
        "items": cartItems.map(item => ({
          "stem_makercart_id": item.id,
          "qty": 1,
          "grade": item.grade,
          "focus": item.focus,
          "number_of_students": item.numberOfStudents,
        }))
      };
      
      const response = await StemStudioService.post(data);
      confirmationModal.value.disableButtons();
      if (response.status === 200) {
        localStorage.removeItem("cartItems");
        successModal.value.updateVisibilityModal(true);
        confirmationModal.value.enableButtons();
        closeAllPopup();
      } else if (response.status === 404){
        confirmationModal.value.enableButtons();
        closeAllPopup();
        updateVisibilityModalLogin(true);
      }
    } else {
      console.log('No items in the cart.');
    }
  } catch (error) {
    console.error('Error posting cart data:', error.message);
  }
}

function adjustHeight() {
  const width = window.innerWidth;
  const height = width <= 768 ? width * (1397 / 1440) : width * (295 / 1440);
  const dynamicHeightDiv = document.getElementById('dynamicHeightDiv');

  if (dynamicHeightDiv) {
    dynamicHeightDiv.style.height = `${height}px`;
    dynamicHeightDiv.style.borderRadius = width <= 768 ? '0px 0px 35px 0px' : '0px 0px 35px 35px';
  }
}

function setBackgroundImage() {
  const width = window.innerWidth;
  const dynamicHeightDiv = document.getElementById('dynamicHeightDiv');

  if (dynamicHeightDiv) {
    dynamicHeightDiv.style.backgroundImage = width <= 768 ? "url('/images/maker-cart/hero-banner-mobile.svg')" : "url('/images/maker-cart/hero-banner.svg')";
    
    if (width <= 768) {
      const discussionInputContainer = document.getElementById('discussion-input-container');
      if (discussionInputContainer) {
        discussionInputContainer.style.bottom = '-50px';
      }
    }
  }
}

function updateVisibilityModalMakerCartDetail(visible, id) {
  makerCartDetail.value.updateVisibilityModal(visible, id);
}

function updateVisibilityModalUserCart(visible) {
  userCart.value.updateVisibilityModal(visible);
}

function updateVisibilityModalConfirmation(visible) {
  const storedCart = localStorage.getItem('cartItems');
  
  if (storedCart) {
    const cartItems = JSON.parse(storedCart);
    
    if (cartItems.length < 4) {
      minimumRequestModal.value.updateVisibilityModal(true);
      return;
    }
  }
  
  confirmationModal.value.updateVisibilityModal(visible);
}

function updateVisibilityModalSuccess() {
  postCartData();
}

function closeAllPopup() {
  userCart.value.updateVisibilityModal(false);
  confirmationModal.value.updateVisibilityModal(false);
}

function updateVisibilityModalLogin(visible) {
  loginModal.value.updateVisibilityModal(visible);
}

function updateCartCount() {
  const storedCart = localStorage.getItem('cartItems');
  cartCount.value = storedCart ? JSON.parse(storedCart).length : 0;
}

function handleConfirm() {
  updateVisibilityModalSuccess(true);
}

function handleCancel() {
  console.log('Modal canceled');
}

const checkAuthToken = async () => {
  try {
    const authToken = await cookiesService.getCookie('authToken');
    if (authToken) {
        // currentAppState.value = AppState.isLogin;
      clearInterval(intervalId);
    }
  } catch (error) {
    console.error(error);
  }
};

checkAuthToken();

const intervalId = setInterval(checkAuthToken, 500);

const onItemSelected = (dataFilter) => {
  activeItems.value = dataFilter;

  getAll();
  // Implementasi lebih lanjut sesuai kebutuhan aplikasi Anda
};

</script>

<style>
.content-container-maker-cart {
  display: flex;
}

.search-bar-maker-cart {
  display: flex;
  align-items: center;
  margin: 20px 0px;
  position: relative;
  width: 100%;
}

.search-bar-maker-cart input {
  flex: 1;
  padding: 5px;
  margin-right: 10px;
}

.search-bar-maker-cart input::placeholder {
  margin-top: 0.4em;
}

.item-list-maker-cart {
  flex-wrap: wrap;
  padding: 20px 0px;
  justify-content: center;
}

.item-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.item-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}

.item-grid > * {
  flex: 1 1 calc(50% - 20px); /* Tetap 2 kolom */
  max-width: calc(50% - 20px); /* Tetap 2 kolom */
  box-sizing: border-box;
  height: auto; /* Pastikan tinggi auto sehingga tidak terpengaruh oleh gaya lainnya */
}

@media (max-width: 768px) {
  .item-grid > * {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .item-grid-container {
    grid-template-columns: 1fr;
  }
}

.no-items-message {
  text-align: center;
  width: 100%;
  padding: 20px;
}

.no-search-message {
  text-align: center;
  width: 100%;
  padding: 20px;
}

.content-container-maker-cart {
  width: 80%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .content-container-maker-cart {
    width: 90%;
    margin: 0 auto;
  }
}

.search-icon {
  position: absolute;
  left: 10px;
  font-size: 18px;
  color: #888;
  display: flex;
  justify-content: center;
}

.search-bar-maker-cart input {
  flex: 1;
  padding: 5px 10px 5px 30px;
  margin-right: 10px;
}

.loading-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Atur tinggi sesuai kebutuhan */
}
</style>
