<template>
  <div id="dynamicHeightDiv" class="background-style-2">
  </div>

  <!-- Detailed Profile Section -->
  <div class="container-profile">
    <div v-if="user" class="profile-photo" style="position: relative;">
      <img
        style="position: absolute; top: -90px; min-width: 20px; min-height: 20px; height: 200px; border-radius: 100px; border: 10px solid rgb(0, 61, 106);"
        :src="user.image" alt="">
    </div>
    <div v-if="user" class="main-content">
      <div class="detailed-profile-container">
        <div class="name-email">
          <div style="color: #003D6A;font-weight: 600;font-size: 2em;">{{ user.firstname }} {{ user.lastname }}</div>
          <div style="color: #003D6A;font-weight: 600;font-size: 1.2em;">{{ user.email }}</div>
        </div>
        <div class="detailed-profile-icons">
          <span>Public</span>
          <img src="/images/eye.svg" alt="">
        </div>
      </div>
    </div>
  </div>

  <!-- Full Content Section -->
  <div class="container-profile mb-10">
    <div class="sidebar-profile">
      <ul style="margin-right: 1.2em;">
        <template v-if="isAdmin">
          <li @click="redirectToAdmin()">
            Admin
          </li>
        </template>
        <li v-for="(menu, index) in menuItems" :key="index"
          :class="{ active: currentIndex === index, disabled: (menu.disabled && !menu.redirect) }"
          @click="setActive(index, menu.disabled)">
          {{ menu.label }}
        </li>
      </ul>
    </div>
    <div class="main-content">
      <div v-if="currentIndex === 0">
        <!-- Content for Account -->
      </div>
      <div v-if="currentIndex === 1">
        <!-- Content for Security Settings -->
      </div>
      <div v-if="currentIndex === 2">
        <!-- Content for Referral -->
      </div>
      <div v-if="currentIndex === 3">
        <!-- Content for Badges -->
      </div>
      <div v-if="currentIndex === 4">
        <!-- Content for My Resources -->
        <div class="tabs">
          <span class="tab" :class="{ 'tab-disabled': true }">STEM Activities</span>
          <span class="tab" :class="{ active: activeTab === 'user-generated-resources' }"
            @click="setActiveTab('user-generated-resources')">User Generated Resources</span>
          <span class="tab" :class="{ active: activeTab === 'favorite-resources' }"
            @click="setActiveTab('favorite-resources')">Favorite Resources</span>
        </div>
        <div class="content">
          <div v-if="activeTab === 'user-generated-resources'">
            <div v-if="isLoading" class="loading-state">
              <div class="spinner-container">
                <div class="mt-5 spinner"></div>
              </div>
            </div>
            <div v-else>
              <div v-if="!userGeneratedResources || userGeneratedResources.length === 0" class="no-resources-message">
                No resources available
              </div>
              <div v-else>
                <div v-for="(item, index) in userGeneratedResources" :key="index">
                  <div class="card-header">
                    <span class="card-header-created">Created:</span> <span class="card-header-date">{{
          formattedDate(item.resource.created_at) }}</span>
                  </div>
                  <div class="card">
                    <div class="card-content" style="display:flex;justify-content: start;gap: 1em;">
                      <div>
                        <img v-if="item.thumbnail" :src="item.thumbnail" style="height: 150px;">
                        <img v-else-if="!item.thumbnail" src="./images/content-placeholder.svg" style="height: 150px;">
                      </div>
                      <div>
                        <div style="display:flex;justify-content:start;gap: 0.5em;">
                          <div
                            style="font-size: 1em;display: flex;align-items: center;margin-right: 5px;font-family: 'League Spartan';color: #003D6A;text-decoration: none;">
                            <img height="20" src="/images/ai-assisted.svg" alt="ai-assisted" style="margin-right: 0.3em;">
                            <span style="font-size: 0.8em;margin-top: 0.2em;">
                              AI Assisted
                            </span>
                          </div>
                          <div v-if="!item.is_liked" @click="likeLessonPackage(item.lesson_package.id)"
                            style="font-size: 1em;display: flex;align-items: center;margin-right: 5px;font-family: 'League Spartan';color: #003D6A;text-decoration: none;cursor: pointer">
                            <img height="20" src="/images/like-01.svg" alt="ai-assisted" style="margin-right: 0.3em;">
                            <span style="font-size:0.8em;color: #E00069;margin-top:0.2em;"> Like </span>
                          </div>
                          <div v-else-if="item.is_liked" @click="likeLessonPackage(item.lesson_package.id)"
                            style="font-size: 1em;display: flex;align-items: center;margin-right: 5px;font-family: 'League Spartan';color: #003D6A;text-decoration: none;cursor: pointer">
                            <img height="20" src="/images/liked-01.svg" alt="ai-assisted" style="margin-right: 0.3em;">
                            <span style="font-size:0.8em;color: #E00069;margin-top:0.2em;"> Liked </span>
                          </div>
                        </div>
                        <h2 :title="item.lesson_package.title">{{ truncateText(item.lesson_package.title) }}</h2>
                        <p>{{ item.lesson_package.short_description }}</p>

                        <div class="tags">
                          <template v-for="(tag, index) in item.lesson_package.software" :key="tag">
                            <span class="tag">{{ tag }}</span>
                            <span v-if="index < item.lesson_package.software.length - 1"> | </span>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <a :href="`/lesson-packages/${item.lesson_package.id}`" target="_blank" class="button">View</a>
                  </div>
                </div>

                <div class="pagination-controls">
                  <button v-for="page in pagesToShow" :key="page" @click="setCurrentPage(page)"
                    :class="{ 'page-active': currentPage === page }" :disabled="currentPage === page">{{ page }}</button>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="activeTab === 'favorite-resources'">
            <div v-if="isLoading" class="loading-state">
              <div class="spinner-container">
                <div class="mt-5 spinner"></div>
              </div>
            </div>
            <div v-else>
              <div v-if="!userGeneratedResources || userGeneratedResources.length === 0" class="no-resources-message">
                No resources available
              </div>
              <div v-else>
                <div v-for="(item, index) in userGeneratedResources" :key="index">
                  <div class="card-header">
                    <span class="card-header-created">Created:</span> <span class="card-header-date">{{
          formattedDate(item.resource.created_at) }}</span>
                  </div>
                  <div class="card">
                    <div class="card-content" style="display:flex;justify-content: start;gap: 1em;">
                      <div>
                        <img v-if="item.thumbnail" :src="item.thumbnail" style="height: 150px;">
                        <img v-else-if="!item.thumbnail" src="./images/content-placeholder.svg" style="height: 150px;">
                      </div>
                      <div>
                        <div style="display:flex;justify-content:start;gap: 0.5em;">
                          <div
                            style="font-size: 1em;display: flex;align-items: center;margin-right: 5px;font-family: 'League Spartan';color: #003D6A;text-decoration: none;">
                            <img height="20" src="/images/ai-assisted.svg" alt="ai-assisted" style="margin-right: 0.3em;">
                            <span style="font-size: 0.8em;margin-top: 0.2em;">
                              AI Assisted
                            </span>
                          </div>
                          <div v-if="!item.is_liked" @click="likeLessonPackage(item.lesson_package.id)"
                            style="font-size: 1em;display: flex;align-items: center;margin-right: 5px;font-family: 'League Spartan';color: #003D6A;text-decoration: none;cursor: pointer">
                            <img height="20" src="/images/like-01.svg" alt="ai-assisted" style="margin-right: 0.3em;">
                            <span style="font-size:0.8em;color: #E00069;margin-top:0.2em;"> Like </span>
                          </div>
                          <div v-else-if="item.is_liked" @click="likeLessonPackage(item.lesson_package.id)"
                            style="font-size: 1em;display: flex;align-items: center;margin-right: 5px;font-family: 'League Spartan';color: #003D6A;text-decoration: none;cursor: pointer">
                            <img height="20" src="/images/liked-01.svg" alt="ai-assisted" style="margin-right: 0.3em;">
                            <span style="font-size:0.8em;color: #E00069;margin-top:0.2em;"> Liked </span>
                          </div>
                        </div>
                        <h2 :title="item.lesson_package.title">{{ truncateText(item.lesson_package.title) }}</h2>
                        <p>{{ item.lesson_package.short_description }}</p>
                        <div class="tags">
                          <template v-for="(tag, index) in item.lesson_package.software" :key="tag">
                            <span class="tag">{{ tag }}</span>
                            <span v-if="index < item.lesson_package.software.length - 1"> | </span>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <a :href="`/lesson-packages/${item.lesson_package.id}`" target="_blank" class="button">View</a>
                  </div>
                </div>
                <div class="pagination-controls">
                  <button v-for="page in pagesToShow" :key="page" @click="setCurrentPage(page)"
                    :class="{ 'page-active': currentPage === page }" :disabled="currentPage === page">{{ page }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="currentIndex === 5">
        <!-- Content for Post History -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch, computed } from 'vue';
import UserService from '@/services/userService';
import cookiesService from '@/services/cookiesService';

const user = ref(null);
const isAdmin = ref(false);

const userGeneratedResources = ref([]);
const isLoading = ref(false); // Tambahkan isLoading state
const currentPage = ref(1);
const pageSize = ref(5);
const totalPages = ref(0);

const getProfile = async () => {
  try {
    const authToken = await cookiesService.getCookie("authToken");
    if (authToken) {
      user.value = authToken; // Contoh sederhana untuk menggunakan authToken

      const response = await UserService.getProfile(authToken.token);
      if (response.status === 200) {
        isAdmin.value = response.data.data.user_group_id === 1;
        userGeneratedResources.value = response.data.data.lesson_packages;
      } else {
        throw new Error(`Request failed with status ${response.status}`);
      }
    }
  } catch (error) {
    console.log('error', error);
  }
}

// Contoh fungsi untuk memperbarui data berdasarkan halaman
const getProfileLessonPackages = async (page = 1, limit = 5, favorite = false) => {
  try {
    isLoading.value = true; // Set isLoading menjadi true ketika mulai request
    const authToken = await cookiesService.getCookie("authToken");
    if (!authToken) {
      throw new Error("Auth token is not available");
    }

    const response = await UserService.getProfileLessonPackages(authToken.token, page, limit, favorite);
    if (response.status === 200) {
      userGeneratedResources.value = response.data.data.rows;

      response.data.data.rows.forEach(element => {
        element.lesson_package.software = parseSoftware(element.resource.payload);
      });

      totalPages.value = response.data.data.total_pages;
    } else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (error) {
    console.error('error', error);
  } finally {
    isLoading.value = false; // Set isLoading menjadi false setelah request selesai
  }
};

const likeLessonPackage = async (id) => {
  try {
    isLoading.value = true; // Set isLoading menjadi true ketika mulai request
    const authToken = await cookiesService.getCookie("authToken");
    if (!authToken) {
      throw new Error("Auth token is not available");
    }

    const response = await UserService.likeLessonPackage(authToken.token, id);
    if (response.status === 200) {
      setActiveTab('favorite-resources');
    } else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (error) {
    console.error('error', error);
  } finally {
    isLoading.value = false; // Set isLoading menjadi false setelah request selesai
  }
};

watch([currentPage, pageSize], () => {
  getProfileLessonPackages(currentPage.value, pageSize.value);
});

const parseSoftware = (rawData) => {
  const result = JSON.parse(rawData);
  if (result.software) {
    return result.software;
  } else {
    return [];
  }
}

const currentIndex = ref(4);
const menuItems = ref([
  { label: 'Account', disabled: true, redirect: true },
  { label: 'Security Settings', disabled: true, redirect: true },
  { label: 'Referral', disabled: true, redirect: true },
  { label: 'Badges', disabled: true, redirect: true },
  { label: 'My Resources', disabled: false },
  { label: 'Post History', disabled: true, redirect: true }
]);
const activeTab = ref('user-generated-resources');

const redirectToAdmin = () => {
  window.open('/admin/china-us');
};

const setActive = (index, isDisabled) => {
  if (!isDisabled) {
    currentIndex.value = index;
  }

  switch (index) {
    case 0:
      window.open(`${process.env.VUE_APP_LOGIN_URL}/account`, '_blank');
      break;
    case 1:
      window.open(`${process.env.VUE_APP_LOGIN_URL}/account/change-password`, '_blank');
      break;
    case 2:
      window.open(`${process.env.VUE_APP_LOGIN_URL}/account/referral`, '_blank');
      break;
    case 3:
      window.open(`${process.env.VUE_APP_LOGIN_URL}/account/mlu-badges`, '_blank');
      break;
    case 5:
      window.open(`${process.env.VUE_APP_LOGIN_URL}/account/post-history/${user.value.user_id}`, '_blank');
      break;
    default:
  }
};

const setBackgroundImage = () => {
  const dynamicHeightDiv = document.getElementById('dynamicHeightDiv');
  dynamicHeightDiv.style.backgroundImage = "url('./images/background-2.svg')";
  const width = window.innerWidth;
  const height = width * (200 / 1440);
  dynamicHeightDiv.style.height = `${height}px`;
  dynamicHeightDiv.style.backgroundSize = 'cover';
  dynamicHeightDiv.style.borderRadius = "0px 0px 25px 25px";
};

const setActiveTab = (tabName) => {
  activeTab.value = tabName;

  if (tabName === 'user-generated-resources') {
    getProfileLessonPackages(1, 5, false);
  } else if (tabName === 'favorite-resources') {
    getProfileLessonPackages(1, 5, true);
  }
};

onMounted(async () => {
  setBackgroundImage();

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  if (token) {
    const authToken = await cookiesService.getCookie("authToken");
    authToken.token = token;
    await cookiesService.setCookie("authToken", authToken, {
      /* options */
    });

    // Remove the token parameter from the URL
    urlParams.delete('token');
    const newUrl = window.location.pathname + '?' + urlParams.toString();
    window.history.replaceState({}, document.title, newUrl);
  }

  await getProfile();
  setActive(4, false);
  setActiveTab('user-generated-resources');
});

onUnmounted(() => {
  // Clean up if needed
});

function formattedDate(date1) {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const date = new Date(date1);
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}${getOrdinalSuffix(day)} ${year}`;
}

function getOrdinalSuffix(day) {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1: return "st";
    case 2: return "nd";
    case 3: return "rd";
    default: return "th";
  }
}

const pagesToShow = computed(() => {
  const pages = [];
  const sidePages = 1;

  if (totalPages.value === 1) {
    return [1];
  } else if (totalPages.value === 0) {
    return [1];
  }

  const rangeStart = Math.max(currentPage.value - sidePages, 2);
  const rangeEnd = Math.min(currentPage.value + sidePages, totalPages.value - 1);

  if (rangeStart > 2) {
    pages.push(1, '...');
  } else {
    pages.push(1);
  }

  for (let i = rangeStart; i <= rangeEnd; i++) {
    pages.push(i);
  }

  if (rangeEnd < totalPages.value - 1) {
    pages.push('...', totalPages.value);
  } else {
    pages.push(totalPages.value);
  }

  return pages;
});

const setCurrentPage = (page) => {
  if (page !== '...') {
    currentPage.value = page;
  }
};

const truncateText = (text) => {
  if (text.length > 60) {
    return text.substring(0, 60) + '...';
  }
  return text;
}

</script>


<style>
.container-profile {
  font-family: 'League Spartan';
  display: flex;
  flex-direction: row;
  margin: 0em 2em;
}

.sidebar-profile {
  margin: 0em 1.5em;
  flex: 0 0 200px;
  height: 100%;
  background-color: #ffffff;
  color: #CCCCCC;
  border-right: 2px solid #D8DAE5;
}

.profile-photo {
  margin: 0em 1.5em;
  flex: 0 0 200px;
  height: 100%;
  background-color: #ffffff;
  color: #CCCCCC;
}

.sidebar-profile ul {
  list-style-type: none;
  padding: 0;
}

.sidebar-profile li {
  margin-top: 2px;
  padding: 15px 20px;
  cursor: pointer;
  border-left: 5px solid transparent;
}

.sidebar-profile li.active {
  border-radius: 10px;
  background-color: #003D6A;
  color: #ffffff;
  border-bottom: 0px solid #E00069;
}

.main-content {
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
}

.sidebar-profile li.disabled {
  cursor: not-allowed;
}

.card-header-created {
  color: #CCCCCC;
  font-size: 0.8em;
}

.card-header-date {
  color: #3B4648;
  font-size: 0.8em;
}

.table-container {}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #003D6A;
}

tr:hover {
  background-color: #f5f5f5;
}

.lesson-plan {
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 4px;
  color: #3B4648;
}

.view-button {
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 4px;
  text-decoration: none;
  color: #3B4648;
}

.detailed-profile-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name-email h2,
.name-email h3 {
  color: #003D6A;
  margin: 0;
}

.detailed-profile-icons {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #00925E;
}

.detailed-profile-icons img {
  height: 24px;
  width: auto;
}

.detailed-profile-icons span {
  color: #00925E;
  font-size: 16px;
}

.pagination-controls {
  display: flex;
  justify-content: center;
}

.pagination-controls button {
  font-family: 'League Spartan';
  background-color: #FFFFFF;
  color: #000000;
  border: 1px solid #D6D6D6;
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 15px;
  margin-right: 5px;
}

.pagination-controls button.page-active {
  background-color: #000069;
  color: #FFFFFF;
  border-color: #000069;
}

.sidebar-profile li:hover {
  border-radius: 10px;
  background-color: #003D6A;
  color: #ffffff;
  border-bottom: 0px solid #E00069;
}
</style>
