import http from './httpService';

class TermService {
  async getAllGrades() {
    return await http.get('/grades');
  }
  
  async getAllSubjects() {
    return await http.get('/subjects');
  }
  
  async getAllDomains() {
    return await http.get('/domains');
  }

  async getAllSoftwares() {
    return await http.get('/softwares');
  }

  async getAllStudentProfiles() {
    return await http.get('/student-profiles');
  }    

  async getTermsByName(param) {
    const url = http.getUrlWithParams('/terms', param);
    return await http.get(url);
  }
}

export default new TermService();
