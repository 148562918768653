import http from "./httpService";

class StemStudioService {

  async getAll(param) {
    const url = http.getUrlWithParams('stem-studio/makercart', param);
    return await http.get(url);
  }

  filterMakercarts(page, limit, sortBy, searchQuery, filterParams, data) {
    // Mengambil data makercarts
    let filteredData = data;

    // Menambahkan filter pencarian (searchQuery)
    if (searchQuery) {
      filteredData = filteredData.filter((item) =>
        item.makercart_activity.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.activity_description.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Menambahkan filter berdasarkan grades
    if (filterParams.grades.length > 0) {
      filteredData = filteredData.filter((item) =>
        filterParams.grades.some(grade => item.grade_level.includes(grade))
      );
    }

    // Menambahkan filter berdasarkan hardware
    if (filterParams.hardware.length > 0) {
      filteredData = filteredData.filter((item) =>
        filterParams.hardware.some(hw => item.hardware_integration && item.hardware_integration.includes(hw))
      );
    }

    // Menambahkan filter berdasarkan prototypingMaterials
    if (filterParams.prototypingMaterials.length > 0) {
      filteredData = filteredData.filter((item) =>
        filterParams.prototypingMaterials.some(pm => item.prototyping_material_integration && item.prototyping_material_integration.includes(pm))
      );
    }

    // Menambahkan filter berdasarkan subjects
    if (filterParams.subjects.length > 0) {
      filteredData = filteredData.filter((item) =>
        filterParams.subjects.some(subject => item.subject.includes(subject))
      );
    }

    // Mengurutkan data berdasarkan sortBy
    if (sortBy === "newest") {
      filteredData.sort((a, b) => b.id - a.id);
    } else if (sortBy === "oldest") {
      filteredData.sort((a, b) => a.id - b.id);
    }

    // Menghitung total halaman
    const totalRows = filteredData.length;
    const totalPages = Math.ceil(totalRows / limit);

    // Mengatur pagination
    const start = (page - 1) * limit;
    const end = page * limit;
    const paginatedData = filteredData.slice(start, end);

    // Mapping data untuk menambahkan properti 'image'
    const mappedData = paginatedData.map(item => ({
      ...item,
      image: item.image_thumbnail && item.image_thumbnail.length > 0 ? item.image_thumbnail[0] : ''
    }));

    return { filteredData: mappedData, totalPages, totalRows };
  }
  
  async getById(id) {
    return await http.get(`/stem-studio/makercart/${id}`);
  }

  async post(data) {
    try {
      const response = await http.post("/stem-studio/orders", data);
      return response;
    } catch (error) {
      if (error.response.status === 404) {
        return error.response;
      }
      throw error;
    }
  }

  async getMaterialById(id) {
    return await http.get(`/stem-studio/material-needed/${id}`);
  }  
}

export default new StemStudioService();
