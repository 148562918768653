<template>
  <div>
    <ModalComponent :visible="showModal" @update:visible="updateVisibilityModal">
      <div class="modal-content modal-content-1" style="padding: 0px;">
        <div class="header-modal">
          <img class="header-img-modal" height="18" src="/images/icon/cart.svg" alt="Document Download">
          <div style="line-height: 1.4;">
            Add to Cart
          </div>
        </div>

        <div v-if="paginatedItems.length > 0" class="container-modal-scroll scrollbar" id="style-4" style="height: 458px;">
          <div class="content-cart">
            <div v-for="(item, index) in paginatedItems" :key="index" class="content-item">
              <div class="content-img">
                <img class="image-item" :src="item.image" alt="">
              </div>
              <div class="content-details">
                <div class="details-header">
                  <span>
                    <h1 class="color-primary">{{ item.title }}</h1>
                  </span>
                  <img src="/images/admin/trash.svg" style="cursor: pointer;" alt="" @click="removeItem(index)">
                </div>

                <div>
                  <div class="content-description color-primary">
                    {{ item.description }}
                  </div>

                  <div class="terms-container mt-3">
                    <div class="term">
                      <div>
                        <h5 class="fs-1 color-green">Number of Students</h5>
                      </div>
                      <div>{{ item.numberOfStudents }}</div>
                    </div>

                    <div class="term">
                      <div>
                        <h5 class="fs-1 color-green">Subject</h5>
                      </div>
                      <div v-if="Array.isArray(item.subject)">
                        <div v-for="(element, index) in item.subject" :key="index">{{ element }}</div>
                      </div>
                      <div v-else>
                          {{ item.subject }}
                      </div>
                    </div>

                    <div class="term">
                      <div>
                        <h5 class="fs-1 color-green">Grade</h5>
                      </div>
                      <div>{{ item.grade }}</div>
                    </div>

                    <div class="term">
                      <div>
                        <h5 class="fs-1 color-green">Focus</h5>
                      </div>
                      <div>{{ item.focus }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="container-modal">
          <div class="empty-cart-message">
            <div class="modal-icon"><img src="/images/maker-cart/maker-cart-empty.svg" alt="Save Icon" style="height: 260px;"></div>
            <div style="display: flex; justify-content: center; font-size: 1em;">
              <h1 class="color-primary">Your cart is empty!</h1>
            </div>
            <div style="display: flex; justify-content: center; font-size: 1em; margin-bottom: 20px;">Browse our Makercart Activities and request a quote!</div>
          </div>
        </div>        

        <div v-show="totalPages > 0" class="pagination-controls">
          <img src="/images/chinaus/vector-right.svg" class="mr-2" alt="" @click="changePage(currentPage - 1)">
          <button v-for="page in totalPages" :key="page" :class="{ 'page-active': page === currentPage }" @click="changePage(page)">
            {{ page }}
          </button>
          <img src="/images/chinaus/vector-left.svg" class="ml-2" alt="" @click="changePage(currentPage + 1)">
        </div>

        <div class="modal-footer" style="margin-top: 0px;">
          <button class="button-email request-quote-button" @click="updateVisibilityConfirmationModal(true)" :class="{ 'disabled': cartItems.length === 0 }" :disabled="cartItems.length === 0">
            Request a Quote
          </button>
        </div>
      </div>
    </ModalComponent>
  </div>
</template>

<script setup>
import { ref, defineExpose, defineEmits, computed } from 'vue';
import ModalComponent from '@/components/ModalComponent.vue';

const emit = defineEmits(['update:confirmation', 'refreshCart', 'triggerUpdateVisibilityModalSuccess', 'triggerUpdateVisibilityModalConfirmation']);

const showModal = ref(false);
const cartItems = ref([]);
const currentPage = ref(1);
const itemsPerPage = 4;

// Function to calculate total pages
const totalPages = computed(() => {
  return Math.ceil(cartItems.value.length / itemsPerPage);
});

// Function to get paginated items
const paginatedItems = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage;
  const end = start + itemsPerPage;
  return cartItems.value.slice(start, end);
});

// Function to go to the specific page
const changePage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
};

function updateVisibilityModal(visible) {
  showModal.value = visible;
  if (visible) {
    const storedCart = localStorage.getItem('cartItems');
    if (storedCart) {
      cartItems.value = JSON.parse(storedCart);
    } else {
      cartItems.value = [];
    }
  }
}

function updateVisibilityConfirmationModal(visible) {
  emit('update:confirmation', visible);
  emit('triggerUpdateVisibilityModalConfirmation', visible);
}

function removeItem(index) {
  cartItems.value.splice(index, 1);
  localStorage.setItem('cartItems', JSON.stringify(cartItems.value));
  emit('refreshCart'); // Emit event untuk memberi tahu bahwa keranjang diperbarui
}

defineExpose({
  updateVisibilityModal
});
</script>

<style>
.header-modal {
  display: flex;
  color: #00925E;
  font-weight: 600;
  font-size: 1.2em;
  gap: 10px;
  background-color: white;
}

.header-img-modal {
  filter: invert(25%) sepia(82%) saturate(3304%) hue-rotate(151deg) brightness(99%) contrast(101%);
  height: 25px;
}

.content-cart {
  height: 100%;
}

.content-item {
  display: flex;
}

.content-img {
  flex: 10%;
  padding: 13px 15px 13px 0px;
}

.content-details {
  flex: 70%;
  padding: 8px 20px 3px 10px;
}

.details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: .7em;
}

.terms-container {
  display: flex;
  flex-wrap: wrap;
}

.content-description {
  font-size: 0.95em;
}

.term {
  flex: 1 1 50%;
  margin-bottom: 10px;
  font-size: 0.95em;
}

.image-item {
  height: 114px;
  border-radius: 20px;
  width: 176px;
  object-fit: cover;
}

@media (min-width: 768px) {
  .term {
    flex: 1 1 25%;
  }
}

.empty-cart-message {
  height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.request-quote-button {
  margin-left: auto;
}
</style>
