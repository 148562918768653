<template>
    <aside class="sidebar-maker-cart">
        <!-- Main container for filters -->
        <div class="filters-maker-cart">
            <div style="display: flex; gap: 10px;">
                <img height="22" src="/images/chinaus/sort.svg" alt=""> Sort by
            </div>

            <!-- Dividing line -->
            <div class="border-line"></div>

            <!-- Select component for sorting options -->
            <SelectComponent :options="['By Newest', 'By Oldest']" :icon="`/images/arrow-bottom.svg`"
                v-model="selectedSort" @change="onSortChange"></SelectComponent>

            <div class="filter-label mt-5">
                <img height="22" src="/images/maker-cart/setting-4.svg" alt="">
                <div>Filter</div>
            </div>

            <!-- Dividing line -->
            <div class="border-line"></div>

            <!-- Accordion component for filter -->
            <div v-for="(items, category) in group" :key="category">
                <AccordionItem class="mb-4" :collapsedIcon="`/images/arrow-bottom.svg`"
                    :expandedIcon="`/images/chinaus/arrow-left.svg`">
                    <template v-slot:title>
                        <div class="fs-11"
                            style="display: flex; justify-content: center; align-items: center; gap: 5px;">
                            <div style="margin: 0px auto; line-height: 1;">
                                {{ items.title }}
                            </div>
                            <div style="position: relative;position: relative;display: flex;justify-content: center;" v-if="showInfo[category] && showInfo[category].title">
                                <img src="/images/maker-cart/info-circle.svg" style="height: 17px !important;" alt=""
                                    @mouseover="showInfo[category].show = true"
                                    @mouseleave="showInfo[category].show = false">
                                <div v-if="showInfo[category].show" class="info-box standart-font">
                                    {{ showInfo[category].title }}
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-slot:content>
                        <div class="subject-container">
                            <span v-for="(item, index) in items.data" class="mx-2" :key="index"
                                :class="['subject-item', { 'select-item-active': isActiveItem(category, item) }]"
                                @click="toggleActiveItem(category, item)">
                                {{ item.title }}
                            </span>
                        </div>
                    </template>
                </AccordionItem>
            </div>
        </div>
    </aside>
</template>

<script setup>
import { ref, onMounted, defineEmits } from 'vue';
import SelectComponent from '@/components/common/SelectComponent.vue';
import AccordionItem from '@/views/ChinaUs/AccordionItem.vue';
import TermService from '@/services/termService';

const emit = defineEmits(['sortChanged', 'itemSelected']);
const selectedSort = ref('By Newest');
const group = ref({
    grades: { title: 'Grades', data: [] },
    subjects: { title: 'Subjects', data: [] },
    hardware: { title: 'Hardware', data: [] },
    prototypingMaterials: { title: 'Prototyping Materials', data: [] },
});

const showInfo = ref({
    hardware: {
        show: false,
        title: 'Have an extra Micro:bit or Makey Makey? Eddy will recommend activities that work well with the hardware selected.'
    },
    prototypingMaterials: {
        show: false,
        title: 'Have extra LED lights or pipe cleaners handy in your classroom? Eddy will recommend activities that work well with the prototyping material selected.'
    }
});

onMounted(async () => {
    const gradesResponse = await TermService.getTermsByName({ taxonomy: 'grade', grade_mapping: 'true' });
    if (gradesResponse.status === 200) {
        group.value.grades.data = gradesResponse.data.data;
    }

    const subjectsResponse = await TermService.getTermsByName({ taxonomy: 'subject' });
    if (subjectsResponse.status === 200) {
        group.value.subjects.data = subjectsResponse.data.data.filter(element => element.slug !== 'other' && element.slug !== 'music');
    }

    const hardwareResponse = await TermService.getTermsByName({ taxonomy: 'hardware' });
    if (hardwareResponse.status === 200) {
        group.value.hardware.data = hardwareResponse.data.data;
    }

    const prototypingMaterialsResponse = await TermService.getTermsByName({ taxonomy: 'prototyping-materials' });
    if (prototypingMaterialsResponse.status === 200) {
        group.value.prototypingMaterials.data = prototypingMaterialsResponse.data.data;
    }
});

const activeItems = ref({
    grades: [],
    subjects: [],
    hardware: [],
    prototypingMaterials: []
});

const toggleActiveItem = (category, item) => {
    if (activeItems.value[category].includes(item)) {
        activeItems.value[category] = activeItems.value[category].filter(i => i !== item);
    } else {
        activeItems.value[category].push(item);
    }
    // emit('itemSelected', { category, activeItems: activeItems.value[category] });
    emit('itemSelected', activeItems.value);
};

const isActiveItem = (category, item) => {
    return activeItems.value[category].includes(item);
};

const onSortChange = () => {
    const sortParam = selectedSort.value === 'By Newest' ? 'newest' : 'oldest';
    emit('sortChanged', sortParam);
};
</script>

<style scoped>
.sidebar-maker-cart {
    flex: 0 0 250px;
    padding: 20px 20px 20px 0px;
}

.filters-maker-cart label {
    display: block;
    margin-bottom: 10px;
}

.subject-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.filter-label {
    display: flex;
    gap: 10px;
    font-weight: 500;
    padding: 0.8rem 0rem 0rem;
}
</style>
