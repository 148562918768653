<template>
    <div>
        <ModalComponent :visible="showModal" @update:visible="updateVisibilityModal">
            <!-- Fill in capital here -->
            <div class="container-modal">
                <div class="modal-title">Sign up or Log in required</div>
                <div class="border-header"></div>
                <div class="modal-icon">
                    <img src="/images/maker-cart/sign-up-pana.svg" alt="Save Icon" style="height: 260px;">
                    <!-- Update the src as per your image path -->
                </div>
                <div style="display: flex;justify-content: center;font-size: 1em;">
                    <h1 class="color-primary">Sign up/log in to continue your order</h1>
                </div>
                
                <div style="display: flex;justify-content: center;font-size: 1em;margin-bottom: 20px;">
                    Please log in or sign up to request your Quote.
                </div>

                <div class="modal-button">
                    <button @click="promptSignIn" class="button-primary-line" style="padding: 0.5em 1em; min-width: 100px;">
                        Login
                    </button>
                    <button @click="promptSignUp" class="button-primary" style="padding: 0.5em 1em; min-width: 100px;">
                        Sign Up
                    </button>
                </div>
            </div>
        </ModalComponent>
    </div>
</template>

<script setup>
import { ref, defineExpose } from 'vue';
import ModalComponent from '@/components/ModalComponent.vue';

const showModal = ref(false);

function updateVisibilityModal(visible) {
    showModal.value = visible;
}

function promptSignIn(event) {
  event.preventDefault();
  window.location.href = `${process.env.VUE_APP_LOGIN_URL}/request-login?ref_path=${encodeURIComponent(window.location.pathname)}`;
}

function promptSignUp(event) {
  event.preventDefault();
  window.location.href = `${process.env.VUE_APP_LOGIN_URL}/request-login?tab=show-register&ref_path=${encodeURIComponent(window.location.pathname)}`;
}

defineExpose({
    updateVisibilityModal
});
</script>

<style>

</style>
