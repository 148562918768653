<template>
    <div>
        <ModalComponent :visible="showModal" @update:visible="updateVisibilityModal">
            <div class="modal-content modal-content-1" style="padding: 0px;">
                <div class="header-modal">
                    <div style="height: 18px;"></div>
                </div>
                
                <div class="container-modal-scroll scrollbar" id="style-4">
                    <div v-if="loading" class="spinner-container">
                        <div class="mt-5 spinner"></div>
                    </div>
                    <div v-else style="margin-right: 10px;">
                        <div>
                            <div class="image-gallery-container">
                            <img src="/images/chinaus/arrow-left.svg"  class="arrow arrow-left" @click="scroll('left')" alt="Left Arrow">
                            <div class="image-gallery">
                                <div class="image-thumbnails">
                                <img v-for="(option, index) in makerCart.image_thumbnail" :key="index" :src="option" height="180" alt="">
                                </div>
                            </div>
                            <img src="/images/chinaus/arrow-left.svg"  class="arrow arrow-right" @click="scroll('right')" alt="Right Arrow">
                            </div>
                        </div>

                        <h1 style="font-weight: 550;" class="color-primary mt-4">{{ makerCart.makercart_activity }}</h1>
                        <p class="color-primary">{{ makerCart.activity_description }}</p>
                        
                        <div class="border-line mb-1"></div>

                        <div class="container-azz">
                            <div class="row-azz">
                                <div class="box-azz">
                                    <div class="title-maker-cart-detail mb-2">
                                        <img src="/images/maker-cart/material-provided.svg" alt="">
                                        <div>Material Provided</div>
                                    </div>
                                    <div v-if="loadingMaterials" class="spinner-container">
                                        <div class="spinner"></div>
                                    </div>
                                    <div v-else>
                                        <span class="mr-4" v-for="(option, index) in makerCart.material_needed" :key="index">
                                            {{ option }}
                                        </span>
                                    </div>
                                </div>
                                <!-- <div class="box-azz">
                                    <div class="title-maker-cart-detail mb-2">
                                        <img src="/images/maker-cart/prototyping-material-integration.svg" alt="">
                                        <div>
                                            Prototyping Material Integration
                                        </div>
                                    </div>
                                    <span class="mx-2">{{ makerCart.prototyping_materials }}</span>
                                </div> -->
                                <div class="box-azz">
                                    <div class="title-maker-cart-detail mb-2">
                                        <img src="/images/maker-cart/subject.svg" alt="" style="height: 16px;">
                                            Subject
                                        <img src="/images/maker-cart/info-circle.svg" style="height: 15px !important;margin-bottom: 3px;"
                                            alt="" @mouseover="showInfo = true" @mouseleave="showInfo = false">
                                    </div>
                                    <span v-if="Array.isArray(makerCart.subject)">
                                        <span class="mr-4" v-for="(option, index) in makerCart.subject" :key="index">
                                            {{ option }}
                                        </span>
                                    </span>

                                    <!-- Jika makerCart.subject adalah string -->
                                    <span class="mr-4" v-else>
                                        {{ makerCart.subject }}
                                    </span>
                                    <div v-if="showInfo" class="info-box">
                                        The core academic subject that this activity is best aligned to.
                                    </div>
                                </div>
                                <!-- <div class="box-azz" style="position: relative;">
                                    <div class="title-maker-cart-detail mb-2">
                                        <img src="/images/maker-cart/hardware-integration.svg" alt="">
                                        <div>
                                            Hardware Integration
                                        </div>
                                    </div>
                                    <span class="mx-2">{{ makerCart.hardware }}</span>
                                </div> -->
                                <!-- <div class="box-azz">
                                    <div class="title-maker-cart-detail mb-2">
                                        <img src="/images/maker-cart/standards.svg" alt="">
                                        <div>
                                            Standards
                                        </div>
                                    </div>
                                    <span class="mx-2">{{ makerCart.makercart.standards }}</span>
                                </div> -->
                            </div>
                        </div>

                        <div class="border-line my-1"></div>

                        <div class="container-azz">
                            <div class="row-azz">
                                <div class="box-azz">
                                    <div class="title-maker-cart-detail mb-2">
                                        <img src="/images/maker-cart/grade.svg" alt="">
                                        <div>
                                            Grade
                                        </div>
                                    </div>
                                    <div class="subject-container">
                                        <span class="mr-4 subject-item" style="font-size: 14px;font-weight: 400" v-for="(option, index) in makerCart.grade_level" :key="index"
                                            :class="{ 'subject-item-selected': isSelected(option, selectedGrades) }"
                                            @click="toggleSelection(option, selectedGrades, false)">
                                            {{ option }}
                                        </span>
                                    </div>
                                </div>
                                <div class="box-azz">
                                    <div class="title-maker-cart-detail mb-2">
                                        <img src="/images/maker-cart/people.svg" alt="">
                                        <div>
                                            Number of Students
                                        </div>
                                    </div>
                                    <div class="subject-container">
                                        <div class="select-wrapper">
                                            <select v-model="selectedNumberOfStudents" class="mr-4 subject-item" style="font-size: 14px;font-weight: 400">
                                                <option v-for="option in numberOfStudentsOptions" :key="option"
                                                    :value="option">{{ option }}</option>
                                            </select>
                                            <img src="/images/arrow-bottom.svg" height="15" alt="Collapsed Icon"
                                                class="clickable-image">
                                        </div>
                                    </div>
                                </div>
                                <div class="box-azz">
                                    <div class="title-maker-cart-detail mb-2">
                                        <img src="/images/maker-cart/people.svg" alt="">
                                        <div>
                                            Focus
                                        </div>
                                    </div>
                                    <div class="subject-container">
                                        <span class="mr-4 subject-item" style="font-size: 14px;font-weight: 400"
                                            :class="{ 'subject-item-selected': isSelected('Sustainability', selectedFocus) }"
                                            @click="toggleSelection('Sustainability', selectedFocus, false)">
                                            Sustainability
                                        </span>
                                        <span class="mr-4 subject-item" style="font-size: 14px;font-weight: 400"
                                            :class="{ 'subject-item-selected': isSelected('Engineering Design', selectedFocus) }"
                                            @click="toggleSelection('Engineering Design', selectedFocus, false)">
                                            Engineering Design
                                        </span>
                                        <span class="mr-4 subject-item" style="font-size: 14px;font-weight: 400"
                                            :class="{ 'subject-item-selected': isSelected('Classic Maker', selectedFocus) }"
                                            @click="toggleSelection('Classic Maker', selectedFocus, false)">
                                            Classic Maker
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Bagian tombol di luar area scroll -->
                <div class="modal-footer">
                    <button class="button-email disabled">
                        See Instructional Lesson
                    </button>
                    <button @click="addToCart" class="button-email" :class="{ 'disabled': loadingMaterials }" :disabled="loadingMaterials" style="text-decoration: none;">
                        <img src="/images/maker-cart/cart.svg" alt="Add to Cart">
                        Add to Cart
                    </button>
                </div>
            </div>
        </ModalComponent>
    </div>
</template>

<script setup>
import { ref, defineExpose, onMounted } from 'vue';
import ModalComponent from '@/components/ModalComponent.vue';
import StemStudioService from '@/services/stemStudioService';

const showModal = ref(false);
const showInfo = ref(false);
const loading = ref(false);
const loadingMaterials = ref(false); // State loading khusus untuk material
const makerCart = ref({});

const selectedGrades = ref([]);
const selectedFocus = ref([]);
const selectedNumberOfStudents = ref(80);

const numberOfStudentsOptions = [80, 90, 100, 110, 120, 130, 140, 150, 160];

onMounted(() => {
    selectedGrades.value = [];
    selectedFocus.value = [];
});

async function getById(id) {
    loading.value = true;
    loadingMaterials.value = true; // Mulai loading material
    const response = await StemStudioService.getById(id);
    if (response.status === 200) {
        makerCart.value = response.data.data;
        loading.value = false;
        
        for (let index = 0; index < makerCart.value.material_needed.length; index++) {
            const material = makerCart.value.material_needed[index];
            const materialData = await getMakerCartById(material, index);
            // Update material_needed dengan data baru
            makerCart.value.material_needed[index] = materialData;
        }
    }
    loadingMaterials.value = false; // Selesai loading material
}

async function getMakerCartById(id) {
    const response = await StemStudioService.getMaterialById(id);
    if (response.status === 200) {
        return response.data.data.name;
    }
    return null;
}

function updateVisibilityModal(visible, id) {
    selectedGrades.value = [];
    selectedFocus.value = [];
    
    showModal.value = visible;
    if (id) {
        getById(id);
    }
}

function toggleSelection(item, list, isMultiple) {
    if (isMultiple) {
        const index = list.indexOf(item);
        if (index > -1) {
            list.splice(index, 1); // Remove item if it exists
        } else {
            list.push(item); // Add item if it doesn't exist
        }
    } else {
        if (list.includes(item)) {
            list.length = 0; // Clear the list if item is already selected
        } else {
            list.length = 0; // Clear the list before adding the new item
            list.push(item); // Add the new item
        }
    }
}

function isSelected(item, list) {
    return list.includes(item);
}

function addToCart() {
    let cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    const itemExists = cartItems.some(item => item.id === makerCart.value.id);

    console.log('makerCart.value.id', makerCart.value.id);
    console.log('cartItems', cartItems);

    const grade = selectedGrades.value.length === 0 ? "" : selectedGrades.value[0];
    const focus = selectedFocus.value.length === 0 ? "" : selectedFocus.value[0];

    if (itemExists) {
        alert('The item is already in the cart');
        return;
    }

    if (grade === "" || focus === "") {
        alert('Please select both grade and focus before adding to cart.');
        return;
    }

    cartItems.push({
        id: makerCart.value.id,
        title: makerCart.value.makercart_activity,
        image: makerCart.value.image_thumbnail && makerCart.value.image_thumbnail.length > 0 ? makerCart.value.image_thumbnail[0] : '',
        description: makerCart.value.activity_description,
        numberOfStudents: selectedNumberOfStudents.value,
        subject: makerCart.value.subject,
        grade: grade,
        focus: focus,
    });

    selectedGrades.value = [];
    selectedFocus.value = [];

    localStorage.setItem('cartItems', JSON.stringify(cartItems));
    showModal.value = false;
    const event = new Event('refreshCart');
    window.dispatchEvent(event);
}

function scroll(direction) {
    const container = document.querySelector('.image-thumbnails');
    if (direction === 'left') {
        container.scrollBy({ left: -200, behavior: 'smooth' });
    } else {
        container.scrollBy({ left: 200, behavior: 'smooth' });
    }
}

defineExpose({
    updateVisibilityModal
});
</script>

<style>
.container-azz {
    display: flex;
    flex-wrap: wrap;
}

.row-azz {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.box-azz {
    width: calc(50% - 10px);
    margin-bottom: 20px;
    position: relative;
}

.title-maker-cart-detail {
    font-weight: 500;
    color: #00925E;
    display: flex;
    align-items: center;
    gap: 5px;
    line-height: 1;
}

.title-maker-cart-detail img {
    height: 20px;
    margin: auto 0;
}

.subject-item-selected {
    background-color: #d1e7dd;
    border-radius: 5px;
    padding: 5px;
}

.select-wrapper {
    position: relative;
    display: inline-block;
}

.select-wrapper select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 5px 19px 5px 10px;
}

.select-wrapper .clickable-image {
    position: absolute;
    right: 20px;
    top: 45%;
    transform: translateY(-50%);
    pointer-events: none;
}

.container-modal-large {
    max-height: calc(100vh - 150px);
    overflow-y: auto;
}

@media (max-width: 600px) {
    .box-azz {
        width: calc(100% - 10px);
    }
}

@media (max-width: 991px) {
    .row-azz {
        flex-direction: column;
    }

    .box-azz {
        width: 100%;
        margin: 5px 0;
    }
}

.image-gallery-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.image-gallery {
  width: 90%;
  overflow: hidden;
}

.image-thumbnails {
  display: inline-flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  width: 100%;
  gap: 20px;
}

.arrow {
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: absolute;
  z-index: 1;
}

.arrow-left {
  left: 10px;
}

.arrow-right {
  right: 10px;
  transform: rotate(180deg);
}

@media (max-width: 600px) {
  .image-gallery {
    width: 100%;
  }
}

</style>
